import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { WatsonState } from "./types";
import { gql } from "@apollo/client/core";
import _ from "lodash";

const actions: ActionTree<WatsonState, RootState> = {
  PUBLISH_ALL_WATSON_INTENTS: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            makerCheckerAPI {
              approveAllIntents
            }
          }
        `,
      })
      .then((res) => {
        return _.get(res, "data.makerCheckerAPI.approveAllIntents");
      })
      .catch((err) => {
        throw err;
      });
  },
  PUBLISH_ALL_WATSON_ENTITIES: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            makerCheckerAPI {
              approveAllEntities
            }
          }
        `,
      })
      .then((res) => {
        return _.get(res, "data.makerCheckerAPI.approveAllEntities");
      })
      .catch((err) => {
        throw err;
      });
  },
  PUBLISH_ALL_WATSON_DIALOG_NODES: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            makerCheckerAPI {
              approveAllDialogNodes
            }
          }
        `,
      })
      .then((res) => {
        return _.get(res, "data.makerCheckerAPI.approveAllDialogNodes");
      })
      .catch((err) => {
        throw err;
      });
  },
  ADD_WATSON_INTENT: ({ commit }, { intent, description }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($intent: String!, $description: String!) {
            watsonAPI {
              createIntent(intent: $intent, description: $description)
            }
          }
        `,
        variables: { intent: intent, description: description },
      })
      .catch((err) => {
        throw err;
      });
  },
  ADD_WATSON_EXAMPLE: ({ commit }, { description, intent, text }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($intent: String!, $text: String!, $description: JSON!) {
            watsonAPI {
              createExample(intent: $intent, text: $text, description: $description)
            }
          }
        `,
        variables: { intent, text, description },
      })
      .catch((err) => {
        throw err;
      });
  },
  ADD_WATSON_ENTITY: ({ commit }, { entity, description }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($entity: String!, $description: JSON!) {
            watsonAPI {
              createEntity(entity: $entity, description: $description)
            }
          }
        `,
        variables: { entity, description },
      })
      .catch((err) => {
        throw err;
      });
  },
  ADD_WATSON_VALUE: (
    { commit },
    { entity, value, value_type, patterns, synonyms, description }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $entity: String!
            $value: String!
            $value_type: String!
            $patterns: JSON
            $synonyms: JSON
            $description: JSON!
          ) {
            watsonAPI {
              createValue(
                entity: $entity
                value: $value
                value_type: $value_type
                patterns: $patterns
                synonyms: $synonyms
                description: $description
              )
            }
          }
        `,
        variables: {
          entity,
          value,
          value_type,
          patterns,
          synonyms,
          description,
        },
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WATSON_VALUE: (
    { commit },
    { entity, value, new_value, new_type, new_patterns, new_synonyms, old_values, description }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $entity: String!
            $value: String!
            $new_value: String
            $new_type: String
            $new_patterns: JSON
            $new_synonyms: JSON
            $old_values: JSON
            $description: JSON!
          ) {
            watsonAPI {
              updateValue(
                entity: $entity
                value: $value
                new_value: $new_value
                new_type: $new_type
                new_patterns: $new_patterns
                new_synonyms: $new_synonyms
                description: $description
                old_values: $old_values
              )
            }
          }
        `,
        variables: {
          entity,
          value,
          new_value,
          new_type,
          new_patterns,
          new_synonyms,
          description,
          old_values,
        },
      })
      .then((data) => {
        //TODO: Remove?
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_WATSON_VALUE: ({ commit }, { old_values, new_values: { entity, values } }) => {
    const old_values_list = old_values.values;
    return Promise.all(
      _.map(values, (value: { value: String }, index) => {
        const tempCurrentValue = _.filter(old_values_list, (v) => {
          return v.value === value.value;
        });
        const current_value = _.assign(tempCurrentValue && tempCurrentValue[0], {
          entity: old_values.entity,
          description: old_values.description,
        });
        return graph
          .mutate({
            mutation: gql`
              mutation($old_values: JSON, $entity: String!, $value: String!) {
                watsonAPI {
                  deleteValue(old_values: $old_values, entity: $entity, value: $value)
                }
              }
            `,
            variables: {
              entity,
              value: value.value,
              old_values: current_value,
            },
          })
          .then((data) => {
            //TODO: Remove?
          });
      })
    );
  },
  UPDATE_WATSON_INTENT: (
    { commit },
    { old_values, new_values: { old_intent, new_intent, new_description } }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $old_values: JSON
            $old_intent: String!
            $new_intent: String
            $new_description: String
          ) {
            watsonAPI {
              updateIntent(
                old_values: $old_values
                old_intent: $old_intent
                new_intent: $new_intent
                new_description: $new_description
              )
            }
          }
        `,
        variables: {
          old_values,
          old_intent,
          new_intent,
          new_description,
        },
      })
      .then((data) => {
        //TODO: Remove?
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WATSON_ENTITY: (
    { commit },
    { old_values, new_values: { entity: old_entity, new_entity, new_description } }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $old_values: JSON
            $old_entity: String!
            $new_entity: String
            $new_description: String!
          ) {
            watsonAPI {
              updateEntity(
                old_values: $old_values
                old_entity: $old_entity
                new_entity: $new_entity
                new_description: $new_description
              )
            }
          }
        `,
        variables: { old_values, old_entity, new_entity, new_description },
      })
      .then((data) => {
        //TODO: Remove?
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WATSON_EXAMPLE: (
    { commit },
    { old_values, new_values: { intent, text: old_text, new_text, description } }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $old_values: JSON
            $intent: String!
            $old_text: String
            $new_text: String
            $description: JSON!
          ) {
            watsonAPI {
              updateExample(
                old_values: $old_values
                intent: $intent
                old_text: $old_text
                new_text: $new_text
                description: $description
              )
            }
          }
        `,
        variables: { old_values, intent, old_text, new_text, description },
      })
      .then((data) => {
        //TODO: Remove?
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_WATSON_INTENTS: ({ commit }, payload: [{ intent: String }]) => {
    return Promise.all(
      _.map(payload, (intent: { intent: String }) => {
        return graph
          .mutate({
            mutation: gql`
              mutation($old_values: JSON, $intent: String!) {
                watsonAPI {
                  deleteIntent(old_values: $old_values, intent: $intent)
                }
              }
            `,
            variables: { old_values: intent, intent: intent.intent },
          })
          .catch((err) => {
            throw err;
          });
      })
    ).catch((err) => {
      throw err;
    });
  },
  DELETE_WATSON_ENTITIES: ({ commit }, payload: [{ entity: String }]) => {
    return Promise.all(
      _.map(payload, (entity: { entity: string }, index) => {
        return graph
          .mutate({
            mutation: gql`
              mutation($old_values: JSON, $entity: String!) {
                watsonAPI {
                  deleteEntity(old_values: $old_values, entity: $entity)
                }
              }
            `,
            variables: {
              old_values: entity,
              entity: entity.entity,
            },
          })
          .catch((err) => {
            throw err;
          });
      })
    ).catch((err) => {
      throw err;
    });
  },
  DELETE_WATSON_EXAMPLES: (
    { commit },
    { old_values, new_values: { intent, examples, description } }
  ) => {
    return Promise.all(
      _.map(examples, (example: { text: String }, index) => {
        return graph
          .mutate({
            mutation: gql`
              mutation(
                $old_values: JSON
                $intent: String!
                $example: String!
                $description: String!
              ) {
                watsonAPI {
                  deleteExample(
                    old_values: $old_values
                    intent: $intent
                    example: $example
                    description: $description
                  )
                }
              }
            `,
            variables: {
              old_values: example,
              intent,
              example: example.text,
              description,
            },
          })
          .catch((err) => {
            throw err;
          });
      })
    ).catch((err) => {
      throw err;
    });
  },
  // FIXME: STOP HERE
  FETCH_WATSON_INTENT: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            watsonAPI {
              listIntents
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload);
        commit("SET_WATSON_INTENT", _.get(data, "data.watsonAPI.listIntents.intents"));
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_WATSON_WORKSPACE: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            watsonAPI {
              getWorkspace
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload);
        commit("SET_WATSON_WORKSPACE", _.get(data, "data.watsonAPI.getWorkspace"));
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_WATSON_MY_ENTITIES: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            watsonAPI {
              listEntities
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload);
        commit("SET_WATSON_MY_ENTITIES", _.get(data, "data.watsonAPI.listEntities.entities"));
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_WATSON_DIALOG: ({ commit }, { sort }) => {
    return graph
      .query({
        query: gql`
          query($sort: String) {
            watsonAPI {
              listDialogNodes(sort: $sort)
            }
          }
        `,
        variables: {
          sort,
        },
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload);
        commit(
          "SET_WATSON_DIALOG_NODES",
          _.get(data, "data.watsonAPI.listDialogNodes.dialog_nodes")
        );
      })
      .catch((err) => {
        throw err;
      });
  },
  ADD_WATSON_DIALOG_NODE: ({ commit }, { payload }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $dialog_node: String!
            $description: String!
            $conditions: String
            $parent: String
            $parent_title: String
            $previous_sibling: String
            $output: JSON
            $context: JSON
            $mcr_number: Int
            $slot_number: Int
            $next_step: JSON
            $actions: JSON
            $title: String
            $node_type: String
            $event_name: String
            $variable: String
            $digress_in: String
            $digress_out: String
            $digress_out_slots: String
            $user_label: String
          ) {
            watsonAPI {
              createDialogNode(
                dialog_node: $dialog_node
                description: $description
                conditions: $conditions
                parent: $parent
                parent_title: $parent_title
                previous_sibling: $previous_sibling
                output: $output
                context: $context
                mcr_number: $mcr_number
                slot_number: $slot_number
                next_step: $next_step
                actions: $actions
                title: $title
                node_type: $node_type
                event_name: $event_name
                variable: $variable
                digress_in: $digress_in
                digress_out: $digress_out
                digress_out_slots: $digress_out_slots
                user_label: $user_label
              )
            }
          }
        `,
        variables: {
          dialog_node: payload.dialog_node,
          description: payload.description,
          conditions: payload.conditions,
          parent: payload.parent,
          parent_title: payload.parent_title,
          previous_sibling: payload.previous_sibling,
          output: payload.output,
          context: payload.context,
          mcr_number: payload.mcr_number,
          slot_number: payload.slot_number,
          title: payload.title,
          next_step: payload.next_step,
          node_type: payload.type,
          actions: payload.actions,
          event_name: payload.event_name,
          digress_out_slots: payload.digress_out_slots,
          user_label: payload.user_label,
          variable: payload.variable,
          digress_in: payload.digress_in,
          digress_out: payload.digress_out,
        },
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_WATSON_DIALOG_NODE: ({ commit }, { payload }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $old_values: JSON
            $dialog_node: String!
            $new_dialog_node: String
            $new_description: String
            $new_conditions: String
            $new_conditionArray: [JSON]
            $new_parent: String
            $new_parent_title: String
            $new_previous_sibling: String
            $new_output: JSON
            $new_context: JSON
            $new_metadata: JSON
            $new_mcr_number: Int
            $new_slot_number: Int
            $new_contextArray: [JSON]
            $new_next_step: JSON
            $new_actions: JSON
            $new_title: String
            $new_type: String
            $new_event_name: String
            $new_variable: String
            $new_digress_in: String
            $new_digress_out: String
            $new_digress_out_slots: String
            $new_user_label: String
          ) {
            watsonAPI {
              updateDialogNode(
                old_values: $old_values
                dialog_node: $dialog_node
                new_dialog_node: $new_dialog_node
                new_description: $new_description
                new_conditions: $new_conditions
                new_conditionArray: $new_conditionArray
                new_parent: $new_parent
                new_parent_title: $new_parent_title
                new_previous_sibling: $new_previous_sibling
                new_output: $new_output
                new_context: $new_context
                new_metadata: $new_metadata
                new_mcr_number: $new_mcr_number
                new_slot_number: $new_slot_number
                new_contextArray: $new_contextArray
                new_next_step: $new_next_step
                new_actions: $new_actions
                new_title: $new_title
                new_type: $new_type
                new_event_name: $new_event_name
                new_variable: $new_variable
                new_digress_in: $new_digress_in
                new_digress_out: $new_digress_out
                new_digress_out_slots: $new_digress_out_slots
                new_user_label: $new_user_label
              )
            }
          }
        `,
        variables: {
          old_values: payload.old_values,
          dialog_node: payload.dialog_node,
          new_dialog_node: payload.dialog_node,
          new_description: payload.description,
          new_conditions: payload.conditions,
          new_conditionArray: payload.conditionArray,
          new_parent: payload.parent,
          new_parent_title: payload.parent_title,
          new_previous_sibling: payload.previous_sibling,
          new_output: payload.output,
          new_context: payload.context,
          new_metadata: payload.metadata,
          new_mcr_number: payload.mcr_number,
          new_slot_number: payload.slot_number,
          new_contextArray: payload.contextArray,
          new_title: payload.title,
          new_next_step: payload.next_step,
          new_type: payload.type,
          new_actions: payload.actions,
          new_event_name: payload.event_name,
          new_digress_out_slots: payload.digress_out_slots,
          new_user_label: payload.user_label,
          new_variable: payload.variable,
          new_digress_in: payload.digress_in,
          new_digress_out: payload.digress_out,
        },
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_WATSON_DIALOG_NODE: ({ commit }, { payload }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($old_values: JSON, $dialog_node: String) {
            watsonAPI {
              deleteDialogNode(old_values: $old_values, dialog_node: $dialog_node)
            }
          }
        `,
        variables: {
          old_values: payload.old_values,
          dialog_node: payload.dialog_node,
        },
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;
