<template>
  <div>
    <!-- RESET FORM -->
    <el-form label-width="100px">
      <p>
        This action will reset the cache and database data that are associated with live chat. After
        executed, all active livechats will be resolved and all agents with email account containing
        text below (partial / exact match) will be turned offline and logged out. Use this before
        performing load test. Please handle with care.
      </p>

      <!-- AGENT -->
      <el-form-item label="Agent email">
        <el-input v-model="agentEmail" />
      </el-form-item>

      <!-- BUTTON -->
      <el-button @click="reset" type="danger" plain :loading="loading" :disabled="loading"
        >Reset</el-button
      >
    </el-form>

    <!-- DIVIDER -->
    <el-divider />

    <!-- DEBUGGER -->
    <el-div style="margin-bottom: 20px">
      <el-button @click="refetch" type="info" plain :loading="loading" :disabled="loading"
        >Fetch cache data</el-button
      >

      <v-jsoneditor
        v-model="results"
        :options="jsonOptions"
        height="800px"
        style="margin-top: 20px"
        :loading="loading"
      ></v-jsoneditor>
    </el-div>
  </div>
</template>

<script>
import _ from "lodash";
import VJsoneditor from "v-jsoneditor";
import { gql } from "@apollo/client/core";
import { graph } from "@/store/api";

export default {
  components: { VJsoneditor },
  data() {
    return {
      results: {},
      loading: false,
      agentEmail: "agent_load_testing",

      // json viewer
      jsonOptions: {
        mode: "tree",
        indentation: 2,
        limitDragging: true,
        modes: ["form", "tree", "preview", "code"],
      },
    };
  },
  methods: {
    //
    async reset() {
      try {
        if (_.isEmpty(this.agentEmail)) {
          this.$notify.warn({
            title: "Warning",
            message: "Agent email cannot be empty",
            position: "bottom-right",
          });
          return;
        }

        const confirmed = await this.$confirm(
          `All active livechats will be resolved and all testing agents containing "${this.agentEmail}" on the email will be logged out`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          }
        );

        if (confirmed) {
          this.loading = true;

          const result = await graph.mutate({
            mutation: gql`
              mutation ($agentEmail: String) {
                livechatAPI {
                  resetDebugData(agentEmail: $agentEmail)
                }
              }
            `,
            variables: {
              agentEmail: this.agentEmail,
            },
            fetchPolicy: "no-cache",
          });

          this.results = result.data?.livechatAPI?.resetDebugData;

          this.$notify.success({
            title: "Success",
            message: JSON.stringify(result),
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify.error({
          title: "Error",
          message: _.toString(error),
          position: "bottom-right",
        });
      } finally {
        this.loading = false;
      }
    },
    async refetch() {
      this.$apollo.queries.getDebugData.refetch();
    },
  },
  apollo: {
    getDebugData: {
      query: gql`
        query {
          livechatAPI {
            getDebugData
          }
        }
      `,
      fetchPolicy: "network-only",
      update(result) {
        this.results = _.get(result, "livechatAPI.getDebugData", {});
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
    },
  },
};
</script>
