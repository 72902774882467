<template>
  <div class="create-topic" :class="{ open: isOpen }">
    <div class="create-topic-container">
      <div class="create-topic-container-header">
        <p class="create-topic-container-header-title">Create new topic</p>
        <p class="create-topic-container-header-close" @click="closeCreateTopic">Close</p>
      </div>
      <div class="create-topic-container-input">
        <el-input placeholder="Enter text topic" style="padding-top: 10px" v-model="text">
          <template slot="prepend">
            <div style="width: 50px">Text</div>
          </template>
        </el-input>
        <el-autocomplete
          v-model="answer"
          class="autocomplete-list autocomplete-list-target"
          style="margin-top: 0; padding: 20px 0"
          placeholder="Target"
          :fetch-suggestions="getContentNodes"
        >
          <div slot="prepend" style="width: 50px">Data</div>
          <el-button
            slot="append"
            icon="el-icon-d-arrow-right"
            @click="selectNode({ type: 'content', id: answer })"
          />
        </el-autocomplete>
      </div>

      <div v-if="answer" class="create-topic-container-answer">
        <div
          v-if="
            selectedNodeData(answer) &&
            !selectedNodeData(answer).image &&
            selectedNodeData(answer).text
          "
        >
          <div v-if="checkIsArray(selectedNodeData(answer).text)">
            <p v-for="(text, i) in selectedNodeData(answer).text" :key="'text' + i">
              {{ text }}
            </p>
          </div>
          <div v-else>
            {{ selectedNodeData(answer).text }}
          </div>
          <div v-if="selectedNodeData(answer).buttons">
            <p v-for="(btn, i) in selectedNodeData(answer).buttons" :key="btn.text + i">
              {{ btn.text }}
            </p>
          </div>
        </div>
        <div v-else>
          <p>This intent has no action.</p>
          <p>Please select an action above.</p>
        </div>
      </div>
      <div v-else>
        <p class="has-text-danger">Choose an valid data</p>
      </div>
      <div style="text-align: right">
        <el-button
          @click="saveNewTopic"
          :loading="isLoading"
          :disabled="setDisableButtonCreate()"
          type="primary"
          icon="el-icon-edit"
          >Create</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { isArray } from "lodash";
import { mixin } from "@/helperMethods/trendingTopics/mixins";
import { gql } from "@apollo/client/core";
export default {
  mixins: [mixin],
  props: ["isOpen"],
  data() {
    return { answer: "", text: "", isLoading: false };
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.closeCreateTopic();
      }
    });
  },
  methods: {
    checkIsArray(param) {
      return isArray(param);
    },
    setDisableButtonCreate() {
      return (
        !this.answer ||
        !this.text ||
        !(
          this.selectedNodeData(this.answer) &&
          !this.selectedNodeData(this.answer).image &&
          this.selectedNodeData(this.answer).text
        )
      );
    },
    closeCreateTopic() {
      this.$emit("handler-create-topic", false);
    },

    getContentNodes(value, cb) {
      if (value && this.$store.state.search.content) {
        cb(this.$store.state.search.content.search(value));
      } else {
        cb([]);
      }
    },
    selectNode({ type, id }) {
      const currentRoute = _.get(this, "$router.currentRoute.fullPath");
      if (currentRoute !== "/editor") {
        this.$router.push("/editor");
      }
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
    async saveNewTopic() {
      const text = this.text;
      const data = this.answer;
      this.isLoading = true;
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($data: String, $text: String) {
              createTrendingTopicByAdmin(data: $data, text: $text)
            }
          `,
          variables: {
            data,
            text,
          },
        })
        .catch((e) => {
          this.$message({
            showClose: true,
            type: "error",
            message: `Text ${this.text} or data ${this.answer} created, enter others.`,
          });
          return false;
        });
      if (isSaved) {
        this.$message({
          showClose: true,
          message: "Create new trending successfull",
          type: "success",
        });
      }
      await this.reCallApi();
      this.isLoading = false;
      this.text = "";
      this.answer = "";
    },
    selectedNodeData() {
      const selectedContentNode = this.$store.state.nodes.content[this.answer];
      return selectedContentNode ? selectedContentNode.content : "";
    },
  },
};
</script>

<style lang="scss">
.create-topic {
  opacity: 0;
  position: fixed;
  z-index: -1;
  transition: 0.3s all linear;
  right: -100vw;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: none;
  &.open {
    transition: 0.3s all linear;
    background: rgba(180, 180, 180, 0.451);
    opacity: 1;
    right: 0;
    z-index: 33;
  }
  &-container {
    width: 700px;
    background: white;
    padding: 25px;
    position: absolute;
    right: 20px;
    border-radius: 15px;
    top: 20%;
    &-header {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
      }
      &-title {
        font-size: 20px;
        color: black;
        font-weight: bold;
      }
      &-close {
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
    }
    &-input {
      padding-top: 20px;
    }
    &-answer {
      padding-top: 20px;
      padding-bottom: 5px;
      > div {
        background: #eaeaea;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}
</style>
