<template>
  <div class="customer-journey">
    <div v-if="!editingJourney">
      <el-row>
        <el-col :span="12">
          <h3>Customer Journey</h3>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button size="small" @click="getJourney" style="margin-right: 10px">
            Refresh
          </el-button>
          <el-button type="primary" size="small" @click="newJourney">
            <i class="el-icon-plus"></i> Add
          </el-button>
        </el-col>
      </el-row>
      <el-table v-loading="loadingData" :data="filteredJourneyList" style="width: 100%">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="title" label="Title"></el-table-column>
        <el-table-column prop="channel" label="Channel"></el-table-column>
        <el-table-column prop="actions" label="Actions">
          <template slot-scope="props">
            <el-button
              circle
              type="warning"
              size="mini"
              icon="el-icon-edit"
              @click="editJourney(props.row)"
            >
            </el-button>
            <el-button
              circle
              type="info"
              size="mini"
              icon="el-icon-plus"
              @click="addCustomer(props.row)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="journeyList.length"
        @current-change="setPage"
        style="padding: 10px 0"
      >
      </el-pagination>
    </div>
    <div v-else>
      <el-card>
        <div slot="header" class="clearfix">
          <h3>
            Customer Journey - {{ journey.title }}
            <span @click="journeyDialog = true" style="cursor: pointer"
              ><i class="el-icon-edit"></i
            ></span>
          </h3>
          <el-button style="float: right" size="small" circle @click="editingJourney = false">
            <i class="el-icon-close"></i>
          </el-button>
        </div>
        <JourneyEditor :journey="journey" @save="onSaveJourney" v-if="editingJourney" />
      </el-card>
    </div>

    <el-dialog title="Customer Journey" :visible.sync="journeyDialog" width="40%">
      <div>
        <label>Title</label>
        <el-input placeholder="Title" v-model="journey.title" style="width: 100%"></el-input>
        <br /><br />
        <label>Channel</label>
        <el-select
          v-model="journey.channel"
          placeholder="Select"
          style="width: 100%"
          :disabled="editingJourney"
        >
          <el-option v-for="item in channels" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
        <br /><br />
        <div v-if="journey.channel === 'Whatsapp'">
          <el-select v-model="whatsappEndpoint" placeholder="Select" style="width: 100%">
            <el-option
              v-for="item in whatsappServers"
              :key="'wa' + item.wa_id"
              :label="formatLabel(item.url, item.wa_id)"
              :value="cloudAPISettings?.enabled ? item.wa_id : item.url"
            ></el-option>
          </el-select>
          <br /><br />
        </div>
        <label>Milestones</label>
        <el-input
          v-for="(item, idx) in journey.milestones"
          :key="'milestone' + idx"
          v-model="item.name"
          style="width: 100%; margin-bottom: 10px"
        >
        </el-input>
        <br />
        <el-button type="text" @click="addMilestone"> + add </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="journeyDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveJourney">Save</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Enroll Customer" :visible.sync="enrollDialog" width="40%">
      <label>Customer</label>
      <el-input v-model="customer.name"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="enrollDialog = false">Cancel</el-button>
        <el-button type="primary" @click="enrollDialog = false">Add</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { gql } from "@apollo/client/core";
import JourneyEditor from "./JourneyEditor";
import { v4 as uuid } from "uuid";
import _ from "lodash";

export default {
  components: {
    JourneyEditor,
  },
  data() {
    return {
      journeyList: [],
      journey: {
        id: "",
        title: "",
        channel: "",
        settings: {},
        nodes: {},
        links: {},
        milestones: [],
      },
      customer: {
        id: 0,
        name: "",
      },
      channels: ["Whatsapp", "Email", "SMS", "Webchat"],
      whatsappServers: [],
      whatsappEndpoint: "",
      journeyDialog: false,
      editingJourney: false,
      loadingData: false,
      enrollDialog: false,
      page: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredJourneyList() {
      return this.journeyList.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
    cloudAPISettings() {
      return this.$store.state.modules.whatsapp.cloudAPIConfig;
    },
  },
  mounted() {
    this.getJourney();
    this.fetchWhatsappServer();
  },
  methods: {
    setPage(val) {
      this.page = val;
    },
    fetchWhatsappServer() {
      this.$store.dispatch("GET_WHATSAPP_SERVERS").then((data) => {
        this.whatsappServers = data;
      });
    },
    async getJourney() {
      try {
        this.loadingData = true;
        let response = await this.$apollo.query({
          query: gql`
            query {
              Bot {
                customerJourney
              }
            }
          `,
          fetchPolicy: "network-only",
        });

        var data = _.get(response, "data.Bot.customerJourney", {});
        this.journeyList = _.map(data, (item) => {
          return item;
        });
        this.loadingData = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
        this.journeyList = [];
        this.loadingData = false;
      }
    },
    newJourney() {
      this.journey = {
        id: uuid(),
        title: "",
        channel: "",
        settings: {},
        nodes: {},
        links: {},
        milestones: [],
      };
      this.journeyDialog = true;
      this.editingJourney = false;
    },
    saveJourney(tab, event) {
      if (this.journey.title && this.journey.channel) {
        if (this.whatsappEndpoint) {
          if (this.journey.settings) {
            this.journey.settings.wa_endpoint = this.whatsappEndpoint;
          } else {
            this.journey.settings = {
              wa_endpoint: this.whatsappEndpoint,
            };
          }
        }
        this.onSaveJourney(this.journey);
        this.journeyDialog = false;
        this.editingJourney = true;
      }
    },
    editJourney(item) {
      this.journey = item;
      if (this.journey.settings?.wa_endpoint) {
        this.whatsappEndpoint = this.journey.settings?.wa_endpoint;
      } else {
        this.whatsappEndpoint = "";
      }
      this.journey.milestones = item.milestones || [];
      this.journeyDialog = false;
      this.editingJourney = true;
    },
    addMilestone() {
      let id = 0;
      if (this.journey.milestones.length > 0) {
        id = this.journey.milestones[this.journey.milestones.length - 1].id + 1;
      }
      this.journey.milestones.push({
        id: id,
        name: "",
      });
    },
    addCustomer() {
      this.enrollDialog = true;
    },
    async onSaveJourney(item) {
      try {
        const data = _.cloneDeep(item);
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($data: JSON!) {
              customerCareAPI {
                saveJourney(data: $data)
              }
            }
          `,
          variables: {
            data,
          },
        });

        this.$message({
          message: "Data has been saved.",
          type: "success",
        });

        this.getJourney();
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    formatLabel(url, wa_id) {
      return wa_id ? `${wa_id} (${url})` : url;
    },
  },
};
</script>
