<template>
  <section>
    <template v-if="reRendering">
      <div style="margin-top: 20px; text-align: center; padding: 20px" class="has-text-dark">
        <p>Loading...</p>
        <i style="font-size: 32px" class="has-text-primary el-icon-loading"></i>
      </div>
    </template>
    <template v-else>
      <el-card
        class="box-card webpage-editor-editor"
        body-style="padding: 0;height: 77.5vh; overflow:hidden"
      >
        <div v-if="selectedWebPage" slot="header" class="clearfix">
          <el-row type="flex" align="middle" justify="space-between" style="margin-bottom: 0">
            <el-row type="flex" align="middle" style="flex-grow: 1; margin-bottom: 0">
              <template v-if="selectedWebPage">
                <el-input
                  style="margin-right: 10px; flex-grow: 1; width: auto"
                  size="mini"
                  v-model="selectedWebPage.name"
                  :disabled="isBusy || !canSave"
                >
                  <template slot="prepend">Title</template>
                </el-input>

                <el-date-picker
                  size="mini"
                  style="margin-right: 10px"
                  v-model="selectedWebPage.publish_at"
                  placeholder="Select time to publish changes"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  :disabled="isBusy || !canSave"
                ></el-date-picker>
              </template>
            </el-row>

            <div>
              <el-button
                plain
                type="success"
                size="mini"
                style="margin-right: 10px"
                :disabled="isBusy || !canSave"
                :loading="isBusy"
                @click.prevent="onSave({ status: 'editing' })"
                >Save</el-button
              >

              <el-dropdown
                v-if="isCheckerRole || $store.state.showAdvanced"
                style="margin-right: 10px"
                size="mini"
                split-button
                type="primary"
                @command="onUpdateStatus"
              >
                <i style="margin-right: 10px" v-if="isBusy" class="el-icon-loading"></i>
                Status - {{ selectedWebPage.status.toUpperCase() }}
                <i v-if="selectedWebPage.status === 'editing'" class="el-icon-edit"></i>

                <i v-if="selectedWebPage.status === 'published'" class="el-icon-check"></i>

                <i v-if="selectedWebPage.status === 'approved'" class="el-icon-date"></i>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="isBusy || !canApprove" command="approve">
                    <i style="margin-right: 15px" class="el-icon-date"></i>
                    <span>Approve</span>
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="isBusy || !canPublish" command="publish now">
                    <span>
                      <i style="margin-right: 15px" class="el-icon-check"></i>Publish Now
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    :disabled="isBusy || selectedWebPage.status !== 'approved'"
                    command="reject"
                  >
                    <i style="margin-right: 15px" class="el-icon-close"></i>
                    <span>Reject</span>
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="isBusy" command="delete">
                    <i style="margin-right: 15px" class="el-icon-delete"></i>
                    <span>Delete</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-row>

          <el-row
            style="margin-bottom: 0; margin-top: 5px"
            type="flex"
            align="middle"
            justify="space-between"
          >
            <div>
              <el-radio-group v-model="currentTab" size="mini" style="margin-top: 10px">
                <el-radio-button label="published_tab" v-if="selectedWebPage.content_published"
                  >Published</el-radio-button
                >
                <el-radio-button label="draft_tab">Draft</el-radio-button>
                <el-radio-button
                  label="compare_tab"
                  v-if="selectedWebPage.type !== 'pdf' && selectedWebPage.content_published"
                  >Compare Changes</el-radio-button
                >
              </el-radio-group>
            </div>
            <div>
              <template v-if="editedByList.length > 0">
                <el-popover placement="bottom" trigger="hover">
                  <div>
                    <ul style="list-style-type: none; padding-left: 0">
                      <li
                        v-for="(edited, index) in editedByList"
                        :key="`${selectedWebPage.id}-${index}`"
                      >
                        by <b>{{ edited.author }}</b> at
                        <em>{{ edited.timestamp | moment("YYYY-MM-DD HH:mm:ss") }}</em>
                      </li>
                    </ul>
                  </div>

                  <small
                    slot="reference"
                    style="cursor: pointer; font-style: italic; font-size: 9px"
                    class="has-text-lightgrey"
                  >
                    Last modified by
                    {{ editedByList[editedByList.length - 1].author }}
                    {{
                      editedByList[editedByList.length - 1].timestamp
                        | moment("YYYY-MM-DD HH:mm:ss")
                        | fromNow
                    }}
                  </small>
                </el-popover>
              </template>
              <copy-url-button :webpage="selectedWebPage" />
            </div>
          </el-row>
        </div>

        <template v-if="selectedWebPage">
          <Published v-if="currentTab === 'published_tab'" :selected-web-page="selectedWebPage" />

          <Compare v-else-if="currentTab === 'compare_tab'" :selected-web-page="selectedWebPage" />

          <component
            ref="webpage-content-editor"
            v-else
            :selected-web-page="selectedWebPage"
            :is="selectedWebPageComponentType"
            :is-disabled="!canSave || isBusy"
            @onSave="onSave"
          ></component>
        </template>

        <template v-else>
          <p style="text-align: center; padding: 30px; margin: 30px">No page selected.</p>
        </template>
      </el-card>
    </template>
  </section>
</template>

<script>
import { v4 as uuid } from "uuid";
import { graph } from "@/store/api";
import { gql } from "@apollo/client/core";
import moment from "moment";

import { Validations } from "../Index";

import Published from "./Published";
import Compare from "./Compare";
import TypeWebPage from "./WebPage";
import TypePDF from "./Pdf";
import CopyUrlButton from "./CopyUrlButton.vue";

export default {
  name: "WebPageEditorEditor",
  components: {
    TypeWebPage,
    TypePDF,
    Published,
    Compare,
    CopyUrlButton,
  },
  props: {
    selectedWebPage: {},
    setStatus: {}, // Function
    isSaving: {},
    isDeleting: {},
    isBusy: {},
  },
  mixins: [Validations],
  data() {
    return {
      reRendering: false,
      currentTab: this.selectedWebPage?.content_published ? "published_tab" : "draft_tab",
    };
  },
  watch: {
    selectedWebPage() {
      this.currentTab = this.selectedWebPage?.content_published ? "published_tab" : "draft_tab";
    },
  },
  computed: {
    canSave() {
      const status = _.get(this.selectedWebPage, "status", "editing");
      return status !== "approved";
    },

    canApprove() {
      const status = _.get(this.selectedWebPage, "status", "editing");
      return status === "editing";
    },

    canPublish() {
      const status = _.get(this.selectedWebPage, "status", "editing");
      return status !== "published";
    },

    canReject() {
      const status = _.get(this.selectedWebPage, "status", "editing");
      return status === "approved";
    },

    /**
     * List of author
     */
    editedByList() {
      return _.get(this.selectedWebPage, "edited_by", []);
    },

    /**
     * Check if user allow to approve or reject webpage
     *
     * @return {boolean}
     */
    isCheckerRole() {
      const profileRoles = _.get(
        this.$store.state,
        "profile.roles",
        _.get(this.$store.state, "profile.app_metadata.roles", [])
      );
      const hasNoPermission = _.chain(this.$store.state)
        .get("modules.access_control.roles", [])
        .filter((role) => {
          const roleAttached = _.includes(profileRoles, role.name);
          const roleAllowWebpageChecker = _.get(role, "access.webpage_checker", false);
          return roleAttached && roleAllowWebpageChecker;
        })
        .isEmpty()
        .value();
      return !hasNoPermission;
    },

    /**
     * @description isShowAdvanced getters from vuex state
     * @return {boolean}
     */
    isShowAdvanced() {
      return _.get(this.$store, "getters.isShowAdvanced", false);
    },

    /**
     * @description Is azure storage getter
     * @return {boolean}
     */
    isAzureStorage() {
      return this.$store.getters.isAzureStorage;
    },

    /**
     * @description Is ceph storage getter
     * @return {boolean}
     */
    isCephStorage() {
      return this.$store.getters.isCephStorage;
    },

    /**
     * @description Check if any storage plugin enabled
     * @return {boolean}
     */
    hasAnyStorageEnabled() {
      return this.isAzureStorage || this.isCephStorage;
    },

    /**
     * @description Get selected webpage type component
     * @return {string}
     */
    selectedWebPageComponentType() {
      if (!this.selectedWebPage) {
        return null;
      }

      const isWebPageType =
        _.isNull(this.selectedWebPage.type) || _.isUndefined(this.selectedWebPage.type);

      if (isWebPageType) {
        return "TypeWebPage";
      } else if (this.selectedWebPage.type === "pdf") {
        return "TypePDF";
      }

      return null;
    },
  },
  methods: {
    async onUpdateStatus(command) {
      if (!this.isBusy) {
        switch (command) {
          case "approve":
            if (!this.selectedWebPage.publish_at) {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: `Please select date and time to publish the webpage.`,
              });
              return;
            }

            this.onSave({ status: "approved" });
            break;

          case "publish now":
            this.onPublish();
            break;

          case "reject":
            this.onSave({ status: "editing" });
            break;

          case "delete":
            this.onDelete();
            break;
        }
      }
    },

    /**
     * @description On delete web page confirmation
     * @return {void}
     */
    onDelete() {
      this.$confirm("Are you sure to delete selected web page?").then((_) => {
        this.delete();
      });
    },

    /**
     * @description On delete webpage
     * @return {void}
     */
    delete() {
      this.setStatus("isDeleting", true);

      if (_.has(this.selectedWebPage, "id")) {
        graph
          .mutate({
            mutation: gql`
              mutation ($data: JSON) {
                webPageEditorAPI {
                  delete(data: $data)
                }
              }
            `,
            variables: {
              data: this.selectedWebPage,
            },
          })
          .then((_) => {
            this.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Web page deleted.`,
            });
            this.$emit("onDelete");
          })
          .catch((error) =>
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Failed to delete web page.`,
            })
          )
          .finally(() => this.setStatus("isDeleting", false));
      } else {
        this.$emit("onDelete");
      }
    },

    /**
     * @description Upload image to storage
     * @description Parse all base64 to network image
     * @return {void}
     */
    async onUploadImage() {
      const uploaded = [];
      let m,
        urls = [],
        rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;

      while ((m = rex.exec(this.selectedWebPage.content))) {
        urls.push(m[1]);
      }

      for (let i = 0; i < urls.length; i++) {
        // do the condition here to prevent crash of naming using index
        const isBase64Image = _.includes(urls[i], ["data:image/"]);
        if (isBase64Image) {
          const imageUuid = uuid();
          const imageFile = this.dataURLtoFile(urls[i], `webpage-image-${imageUuid}-${i}`);

          let form = new FormData();
          let response = null;

          form.append("brain", this.$store.state.brain);
          form.append("images", imageFile);

          if (this.isAzureStorage) {
            form.append("name", "microsite");
            response = await this.$rest("post", "miniapp_images_upload", form);

            const responseFile = _.first(response);
            uploaded.push(responseFile);
            this.selectedWebPage.content = this.selectedWebPage.content.replace(
              urls[i],
              responseFile.url
            );
          } else if (this.isCephStorage) {
            form.append("name", "");
            response = await this.$store.dispatch("MINI_APP_UPLOAD_IMAGE", form);

            uploaded.push(response.url);
            this.selectedWebPage.content = this.selectedWebPage.content.replace(
              urls[i],
              response.url
            );
          }
        }
      }
      return uploaded;
    },

    async onPublish() {
      try {
        this.setStatus("isSaving", true);
        if (
          this.currentTab === "draft_tab" &&
          this.$refs["webpage-content-editor"].updateContentFromLocal
        ) {
          this.$refs["webpage-content-editor"].updateContentFromLocal();
        }

        if (this.hasAnyStorageEnabled) {
          const uploaded = await this.onUploadImage();
        }
        const webpage = {
          ...this.selectedWebPage,
          publish_at: moment().format("YYYY-MM-DD H:m:s"),
        };

        const saved = await graph.mutate({
          mutation: gql`
            mutation ($data: JSON) {
              webPageEditorAPI {
                publish(data: $data)
              }
            }
          `,
          variables: {
            data: webpage,
          },
        });

        const newWebPage = _.get(saved, "data.webPageEditorAPI.publish", {});
        this.selectedWebPage.id = newWebPage.id;
        this.$emit("onUpdate", newWebPage);
        this.$notify.success({
          title: "Success",
          position: "bottom-right",
          message: `Web page published.`,
        });

        this.setStatus("isSaving", false);
        this.$nextTick(() => {
          this.reRendering = true;
          setTimeout(() => {
            this.reRendering = false;
          }, 1200);
        });
      } catch (error) {
        console.log("Failed to publish.", error);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Failed to publish web page.`,
        });
        this.setStatus("isSaving", false);
      }
    },

    /**
     * @description On save webpage
     * @return {void}
     */
    async onSave(updatedData = null) {
      try {
        this.setStatus("isSaving", true);
        if (
          this.currentTab === "draft_tab" &&
          this.$refs["webpage-content-editor"].updateContentFromLocal
        ) {
          this.$refs["webpage-content-editor"].updateContentFromLocal();
        }

        if (this.hasAnyStorageEnabled) {
          const uploaded = await this.onUploadImage();
        }

        const webpage = {
          ...this.selectedWebPage,
          ...(updatedData || {}),
        };

        const saved = await graph.mutate({
          mutation: gql`
            mutation ($data: JSON) {
              webPageEditorAPI {
                updateOrCreate(data: $data)
              }
            }
          `,
          variables: {
            data: webpage,
          },
        });

        const newWebPage = _.get(saved, "data.webPageEditorAPI.updateOrCreate", {});
        this.selectedWebPage.id = newWebPage.id;
        this.$emit("onUpdate", newWebPage);
        this.$notify.success({
          title: "Success",
          position: "bottom-right",
          message: `Web page saved.`,
        });

        this.setStatus("isSaving", false);
        this.$nextTick(() => {
          this.reRendering = true;
          setTimeout(() => {
            this.reRendering = false;
          }, 1200);
        });
      } catch (error) {
        console.log("Failed to update or create web page.", error);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Failed to update or create web page.`,
        });
        this.setStatus("isSaving", false);
      }
    },

    /**
     * @description Convert base64 image to File
     * @param {string} dataurl
     * @param {filename} filename
     * @return {File}
     */
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let extension = "jpg";
      switch (mime) {
        case "image/jpeg":
          extension = "jpg";
          break;
        case "image/jpg":
          extension = "jpg";
          break;
        case "image/png":
          extension = "png";
          break;
        case "image/gif":
          extension = "gif";
          break;
      }

      return new File([u8arr], `${filename}.${extension}`, { type: mime });
      // extract content type and base64 payload from original string
      // let pos = dataurl.indexOf(";base64,");
      // let type = dataurl.substring(5, pos);
      // let b64 = dataurl.substr(pos + 8);

      // // decode base64
      // let imageContent = atob(b64);

      // // create an ArrayBuffer and a view (as unsigned 8-bit)
      // let buffer = new ArrayBuffer(imageContent.length);
      // let view = new Uint8Array(buffer);

      // // fill the view, using the decoded base64
      // for (let n = 0; n < imageContent.length; n++) {
      //   view[n] = imageContent.charCodeAt(n);
      // }

      // // convert ArrayBuffer to Blob
      // let blob = new Blob([buffer], { type: type });
      // console.log(blob);
      // return blob;
    },
  },
};
</script>
