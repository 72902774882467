<template>
  <el-row :gutter="30" v-loading="isLoading" class="row-trending-topic">
    <CreateTopic :isOpen="isOpen" @handler-create-topic="setOpenCreate" />
    <div class="button-publish">
      <div class="button-publish-container">
        <el-button
          @click="submitIntent"
          type="primary"
          icon="el-icon-s-promotion"
          :disabled="disableButtonPublish()"
          >Publish</el-button
        >
      </div>
    </div>
    <el-col :span="12" class="col-left" :style="colStyleHeight()"
      ><TrendingList
        :listSubmit="intentIdSubmit"
        :trendingData="trendingTopics"
        :selectIntent="selectIntent"
        :limit="countCurrentlyTopics"
        :setLoading="setLoading"
      />
    </el-col>
    <el-col :span="12" class="col-right" :style="colStyleHeight()"
      ><PublishedList
        @select-intent="selectIntentIdRemove"
        :heightTrending="heightTrending"
        :setLoading="setLoading"
        :handleTimeSort="handleTimeSort"
      />
    </el-col>
  </el-row>
</template>

<script>
import TrendingList from "./TrendingList.vue";
import PublishedList from "./PublishedList.vue";
import CreateTopic from "./CreateTopic/CreateTopic.vue";
import _ from "lodash";
import { gql } from "@apollo/client/core";
import { mixin } from "@/helperMethods/trendingTopics/mixins";
import { returnPublishSuccessMessage } from "@/helperMethods/trendingTopics/methods";
import { TRENDING_TOPIC_STATUS } from "@/helperMethods/trendingTopics/type";
export default {
  props: ["heightTrending"],
  mixins: [mixin],
  components: {
    TrendingList,
    PublishedList,
    CreateTopic,
  },
  data() {
    return {
      intentIdSubmit: [],
      intentIdRemove: [],
      isLoading: false,
      timeSort: 0,
      colHeight: 0,
      newTopic: { text: "", data: "" },
      keyCombo: [
        "ArrowUp",
        "ArrowUp",
        "ArrowUp",
        "ArrowDown",
        "ArrowDown",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
      ],
      currentCombo: [],
      isOpen: false,
    };
  },
  updated() {
    const buttonHeight = document.getElementsByClassName("button-publish")[0].offsetHeight;
    this.colHeight = this.heightTrending - buttonHeight;
  },
  computed: {
    trendingTopics() {
      return this.$store.state.trendingTopics.filter(
        (data) => data.status !== TRENDING_TOPIC_STATUS.CURRENTLY
      );
    },
    countCurrentlyTopics() {
      return this.$store.state.currentlyTopics.length + this.intentIdSubmit.length;
    },
    orderCurrently() {
      return this.$store.state.orderCurrently;
    },
  },
  async created() {
    window.addEventListener("keydown", this.showKeyDown);
    this.isLoading = true;
    this.$store.commit("SET_FILTER_DATE", ["", ""]);
    await Promise.all([
      this.$store.dispatch("TRENDING_TOPICS_FIRST_TIME"),
      this.$store.dispatch("TRENDING_TOPICS"),
    ]);
    this.isLoading = false;
    this.timeSort = 1;
  },
  methods: {
    setOpenCreate(open) {
      this.isOpen = open;
    },
    showKeyDown(e) {
      this.currentCombo.push(e.key);
      if (this.currentCombo.join().includes(this.keyCombo.join())) {
        this.isOpen = true;
        this.currentCombo = [];
      }
    },
    selectIntentIdRemove(id) {
      this.intentIdRemove.push(id);
    },
    async createTopic() {
      this.setLoading();
      const { text = "Hello", data = "hello_data" } = this.newTopic;
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($data: String, $text: String) {
              createTrendingTopicByAdmin(data: $data, text: $text)
            }
          `,
          variables: {
            text,
            data,
          },
        })
        .catch((e) => {
          this.$message({
            showClose: true,
            type: "error",
            message: e.message,
          });
          return false;
        });
      if (isSaved) {
        this.$message({
          type: "success",
          message: "Create successfull",
          showClose: true,
        });
      }
      this.setLoading();
    },
    colStyleHeight() {
      return {
        height: `80vh`,
        // height: `${this.colHeight}px`,
      };
    },
    handleTimeSort() {
      this.timeSort++;
    },
    disableButtonPublish() {
      return !(this.timeSort > 1 || this.intentIdSubmit.length > 0);
    },
    selectIntent(data) {
      const index = this.intentIdSubmit.indexOf(data.id);
      if (index < 0) {
        this.intentIdSubmit.push(data.id);
      } else {
        this.intentIdSubmit.splice(index, 1);
      }
      if (this.countCurrentlyTopics == 6) {
        this.$message({
          showClose: true,
          message: "Publish limit has been reached",
          type: "warning",
        });
      }
    },
    setLoading() {
      this.isLoading = !this.isLoading;
    },
    async submitIntent() {
      const dataUpdateOrder = [...this.orderCurrently];
      const orderNext = dataUpdateOrder.length;
      const dataPublishTopic = [...this.trendingTopics]
        .filter((trending) => this.intentIdSubmit.includes(trending.id))
        .map((item, index) => ({ id: item.id, order: index + orderNext + 1 }));
      const dataRemove = [...this.intentIdRemove];
      this.setLoading();
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($dataUpdateOrder: [JSON], $dataPublishTopic: [JSON], $dataRemove: [Int]) {
              publishIntentHandler(
                dataUpdateOrder: $dataUpdateOrder
                dataPublishTopic: $dataPublishTopic
                dataRemove: $dataRemove
              )
            }
          `,
          variables: {
            dataUpdateOrder,
            dataPublishTopic,
            dataRemove,
          },
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "error",
            message: "Publish failed.",
          });
          return false;
        });
      if (isSaved) {
        this.$message({
          showClose: true,
          // message: "Trending topics published",
          message: returnPublishSuccessMessage(dataPublishTopic, dataUpdateOrder, dataRemove),
          type: "success",
        });
      }
      this.setLoading();
      await this.reCallApi();
      this.timeSort = 1;
      this.intentIdSubmit = [];
      this.intentIdRemove = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.button-publish {
  padding: 20px 0;
  text-align: end;
  border-top: 2px solid rgba(168, 168, 168, 0.767);
  &-container {
    > button {
      padding: 10px 35px;
    }
  }
}

.col-left {
  padding-left: 0 !important ;
}
.col-right {
  padding-right: 0 !important ;
}
</style>
