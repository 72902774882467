<template>
  <div>
    <el-table v-loading="loading" :data="automationList" style="width: 100%">
      <el-table-column prop="name" label="Automation Name">
        <template slot-scope="props">
          <span v-if="props.row.details.name">
            {{ props.row.details.name }}
          </span>
          <span v-else>
            <i>No name</i>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="triggeredBy" label="Triggered By"></el-table-column>
      <el-table-column prop="executionTime" label="Send Time">
        <template slot-scope="props">
          <div style="display: grid; grid-template-columns: 20px 1fr; grid-gap: 10px">
            <div style="display: flex; align-items: center">
              <i class="el-icon-time time-icon success"></i>
            </div>
            <div>
              <span>{{ formatDate(props.row.executionTime, "h:mm a") }}</span>
              <br />
              <span>{{ formatDate(props.row.executionTime, "MMM Do, YYYY") }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="success" label="Success"></el-table-column>
      <el-table-column prop="failed" label="Failed"></el-table-column>
      <el-table-column prop="Action" label="Action">
        <template slot-scope="props">
          <el-button
            class="automation-list-btn"
            @click="openDetails(props.row)"
            icon="el-icon-info"
            circle
            type="info"
            size="mini"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="page"
      @current-change="setPage"
      style="padding: 10px 0"
    >
    </el-pagination>

    <el-dialog
      title="Automation Details"
      :visible.sync="automationDetails"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-row :gutter="20">
        <el-col :span="12"> <b>ID</b><br />{{ automation.id }} </el-col>
        <el-col :span="12"> <b>Name</b><br />{{ automation.name }} </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <b>Channels</b><br />
          <div v-for="(item, idx) in automation.channels" :key="'chn' + idx">
            {{ item.name }}
            <span v-if="item.settings && item.settings.endpoint"
              >({{ item.settings.endpoint }})</span
            >
          </div>
        </el-col>
        <el-col :span="12"> <b>Context</b><br />{{ automation.context }} </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <b>Contacts</b><br />
          <div v-if="automation.contactOperations.length > 0">
            OPERATIONS: {{ automation.contactOperations.join(" ") }}
          </div>
          <div v-if="automation.contactStages.length > 0">
            STAGES : [{{ automation.contactStages.join(", ") }}]
            <span v-for="(item, index) in automation.contactStages" :key="'stage' + item.id">
              {{ stagesData[item] }} {{ index === automation.contactStages.length - 1 ? "" : ", " }}
            </span>
          </div>
          <div v-if="automation.contactTags.length > 0">
            TAGS : [{{ automation.contactTags.join(", ") }}]
            <span v-for="(item, index) in automation.contactTags" :key="'tag' + item.id">
              {{ tagsData[item] }} {{ index === automation.contactTags.length - 1 ? "" : ", " }}
            </span>
          </div>
          <div v-if="automation.contactList.length > 0">
            LIST : [{{ automation.contactList.join(", ") }}]
            <span v-for="(item, index) in contactNames" :key="'list' + index">
              {{ item }} {{ index === contactNames.length - 1 ? "" : ", " }}
            </span>
          </div>
          <div v-if="automation.contactAttributes.length > 0">
            VARIABLES :
            <span v-for="(item, index) in automation.contactAttributes" :key="'var' + index">
              [{{ item.attribute }} {{ item.operator }} {{ item.value }}]
              {{ index === automation.contactAttributes.length - 1 ? "" : " AND " }}
            </span>
          </div>
          <div v-if="automation.stateVariables.length > 0">
            <b>State Variables</b><br />
            <span v-for="(item, index) in automation.stateVariables" :key="'vars' + index">
              [{{ item.variable }} {{ item.operator }} {{ item.value }}]
              {{ index === automation.stateVariables.length - 1 ? "" : " AND " }}
            </span>
          </div>
        </el-col>
        <el-col :span="12">
          <b>Messages</b><br />
          <div v-for="(item, index) in automation.messages" :key="'msg' + index">
            <b>#{{ index + 1 }}</b>
            <span v-if="item.contentType === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE">
              {{ whatsappTemplates[item.contentId] }}
            </span>
            <span v-else>
              {{ item.contentId }}
            </span>
            <div v-if="item.contentParameters">
              <div v-for="(itm, idx) in item.contentParameters" :key="'p' + index + idx">
                {{ itm.key }} : {{ itm.type }} {{ itm.value }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <b>Updates</b><br />
          <div v-for="(item, index) in automation.updates" :key="'upd' + index">
            <span v-if="item.failed">[FAILED]</span>
            <span v-else>[SUCCESS]</span>
            {{ item.property }} : 
            <span v-if="item.property === AUTOMATION_OPERATION.ATTRIBUTES">
              <span v-for="(itm, idx) in item.updateItem" :key="'upd'+index+idx">
                [{{ idx }} = {{ itm }}] 
              </span>
            </span>
            <span 
              v-else-if="
                (item.property === AUTOMATION_OPERATION.STAGES || 
                  item.property === OUT_REACH_TAG_TYPE.STAGE) && 
                item.updateTo.length > 0
              "
            >
              {{ stagesData[item.updateTo[0]] }}
            </span>
            <span v-else>
              {{ item.method }}
              <span v-for="(itm, idx) in item.updateTo" :key="'upd' + index + idx">
                {{ tagsData[itm] }} {{ index === item.updateTo.length - 1 ? "" : ", " }}
              </span>
            </span>
          </div>
        </el-col>
        <el-col :span="12">
          <b>Schedule</b><br />
          <div>TYPE : {{ automation.scheduleType }}</div>
          <div v-if="automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.SCHEDULED">
            SCHEDULED: {{ formatDate(automation.startDate, "D MMM YYYY, h:mm a ") }}
          </div>
          <div v-else-if="automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.RECURRING">
            <div>
              START :
              {{
                automation.startDate ? formatDate(automation.startDate, "D MMM YY, h:mm a ") : ""
              }}
            </div>
            <div>
              END :
              {{ automation.endDate ? formatDate(automation.endDate, "D MMM YY, h:mm a ") : "" }}
            </div>
            <div>SUMMARY : {{ sendOutSummary }}</div>
          </div>
        </el-col>
      </el-row>

      <div>
        <b>Contacts</b>
      </div>
      <el-table v-loading="contactLoading" :data="automationContacts">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="primaryContact" label="Primary Contact"></el-table-column>
        <el-table-column prop="result" label="Status">
          <template slot-scope="props">
            <el-tag size="small" type="success" v-if="props.row.result">
              Success
            </el-tag>
            <el-tag size="small" type="danger" v-else>
              Failed
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { gql } from "@apollo/client/core";
import _ from "lodash";
import moment from "moment";
import {
  AUTOMATION_SCHEDULE_TYPE,
  AUTOMATION_CHANNEL_NAME,
  AUTOMATION_MESSAGE_CONTENT_TYPE,
  AUTOMATION_OPERATION,
  OUT_REACH_TAG_TYPE,
} from "@/store/modules/outreach/types";

export default {
  components: {},
  data() {
    return {
      AUTOMATION_SCHEDULE_TYPE,
      AUTOMATION_MESSAGE_CONTENT_TYPE,
      AUTOMATION_OPERATION,
      OUT_REACH_TAG_TYPE,
      automation: {
        id: null,
        name: "",
        context: "",
        enabled: false,
        channels: [],
        messages: [],
        contactTags: [],
        contactStages: [],
        contactList: [],
        contactAttributes: [],
        contactOperations: [],
        stateVariables: [],
        scheduleType: AUTOMATION_SCHEDULE_TYPE.NOT_SET,
        scheduleCron: [],
        startDate: null,
        endDate: null,
        sentTime: null,
        updates: [],
        createdAt: null,
        updatedAt: null,
      },
      automationList: [],
      automationContacts: [],
      contactNames: [],
      loading: false,
      contactLoading: false,
      page: 1,
      pageSize: 15,
      total: 0,
      automationDetails: false,
      sendOutSummary: "",
      clientTimezone: moment.tz.guess(),
    }
  },
  computed: {
    whatsappTemplates() {
      var templates = this.$store.state.whatsapp.templates || [];

      var items = _.reduce(
        templates,
        function (result, value, key) {
          result[value.id] = value.name;
          return result;
        },
        {}
      );

      return items;
    },
    tagsData() {
      var items = _.reduce(
        this.$store.state.outreach.tags.data,
        function (result, value, key) {
          result[value.id] = value.name;
          return result;
        },
        {}
      );

      return items;
    },
    stagesData() {
      var items = _.reduce(
        this.$store.state.outreach.stages.data,
        function (result, value, key) {
          result[value.id] = value.name;
          return result;
        },
        {}
      );

      return items;
    },
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    refreshList() {
      this.page = 1;
      this.getHistory();
    },
    setPage(val) {
      this.page = val;
      this.getHistory();
    },
    formatDate(date, format) {
      return moment(date).tz(this.clientTimezone).format(format);
    },
    openDetails(item) {
      this.automation = item.details;
      this.automation.success = 0;
      this.automation.failed = 0;
      this.automationDetails = true;
      this.contactLoading = true;

      var channel = _.find(this.automation.channels, {
        name: AUTOMATION_CHANNEL_NAME.WHATSAPP,
      });

      if (channel) {
        this.fetchWhatsappTemplates(channel.settings.endpoint);
      }

      this.sendOutSummary = "";
      if (this.automation.scheduleCron.length > 1) {
        var startDate = moment(this.automation.startDate);
        _.forEach(this.automation.scheduleCron, function (value, key) {
          var selectedDate = moment(this.automation.startDate);
          selectedDate.date(value[2]);
          selectedDate.month(value[3]);
          if (startDate.isAfter(selectedDate)) {
            selectedDate.add(1, "years");
          }
          if (key === 0) {
            this.sendOutSummary += selectedDate.format("MMMM Do YYYY");
          } else {
            this.sendOutSummary += ", " + selectedDate.format("MMMM Do YYYY");
          }
        });
        this.sendOutSummary += " at " + this.formatDate(this.automation.startDate, "h:mm a");
      } else if (this.automation.scheduleCron.length === 1) {
        var cron = this.automation.scheduleCron[0];
        if (cron[4] !== "*") {
          this.sendOutSummary =
            "Every week on " + this.formatDate(this.automation.startDate, "dddd h:mm a");
        } else if (cron[3] !== "*") {
          this.sendOutSummary =
            "Every year on " + this.formatDate(this.automation.startDate, "MMMM Do h:mm a");
        } else if (cron[2] === "*/2") {
          this.sendOutSummary =
            "Every 2 days at " + this.formatDate(this.automation.startDate, "h:mm a");
        } else if (cron[2] !== "*") {
          this.sendOutSummary =
            "Every month on " + this.formatDate(this.automation.startDate, "Do h:mm a");
        } else {
          this.sendOutSummary =
            "Every day at " + this.formatDate(this.automation.startDate, "h:mm a");
        }

        if (this.automation.endDate) {
          this.sendOutSummary +=
            " till " + this.formatDate(this.automation.endDate, "MMMM Do YYYY, h:mm a");
        } else {
          this.sendOutSummary += " till never";
        }
      }

      this.automationContacts = item.contactList
      this.contactNames = [];
      for (let index = 0; index < this.automationContacts.length; index++) {
        if(this.automationContacts[index].result) {
          this.automation.success++;
        } else {
          this.automation.failed++;
        }
        this.automationContacts[index].name = this.automationContacts[index].firstName + " " + 
            this.automationContacts[index].lastName;
        if (_.includes(this.automation.contactList, this.automationContacts[index].id)) {
          this.contactNames.push(this.automationContacts[index].name);
        }
      }

      this.contactLoading = false;
    },
    fetchWhatsappTemplates(wa_endpoint) {
      this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
        data: { wa_endpoint: wa_endpoint },
      });
    },
    async getHistory() {
      this.loading = true;
      const page = this.page;
      const take = this.pageSize;
      try {
        let response = await this.$apollo.mutate({
          mutation: gql`
            mutation (
              $page: Int!
              $take: Int!
            ) {
              outreachAutomationAPI {
                getHistory(
                  page: $page
                  take: $take
                )
              }
            }
          `,
          variables: {
            page,
            take,
          },
        });

        var data = _.get(response, "data.outreachAutomationAPI.getHistory", []);
        this.automationList = data.data;
        _.forEach(this.automationList, function(value) {
          value.details = JSON.parse(value.details);
          value.contactList = JSON.parse(value.contactList);
          value.success = 0;
          value.failed = 0;
          _.forEach(value.contactList, function(contact) {
            if(contact.result) {
              value.success++;
            } else {
              value.failed++;
            }
          });
        });
        this.total = data.count;
        this.loading = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
        this.automationList = [];
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="scss">
.tab-campaign .el-table th {
  color: #1d57d8;
  font-weight: 600;
  font-size: 16px !important;
}

.tab-campaign .el-table tr {
  color: #000000;
  font-size: 14px;
}

.tab-campaign .time-icon {
  font-size: 16;
}

.tab-campaign .time-icon.success {
  color: #13ce66;
}

.tab-campaign .automation-list-btn {
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
}
</style>
