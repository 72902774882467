import { graph } from "@/store/api";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { SupervisorState } from "./types";
import { gql } from "@apollo/client/core";
import _ from "lodash";
import { parseSessions } from "./utils";

const PAGINGATE_LIVECHAT_QUERY = require("@/graphql/LIVECHATS_PAGINATE_SESSIONS.gql");

const actions: ActionTree<SupervisorState, RootState> = {
  SHOW_AGENT_LIST({ state, commit }, { status }) {
    if (state.currentExpanded) {
      commit("SET_CURRENT_EXPANDED", null);
    }
    if (!status) {
      commit("SET_CURRENT_MODE", null);
    }
  },
  SHOW_ONLINE_AGENTS({ commit, dispatch }) {
    commit("SET_CURRENT_MODE", "ONLINE_AGENTS");
    dispatch("SHOW_AGENT_LIST", { status: "online" });
  },
  SHOW_OFFLINE_AGENTS({ commit, dispatch }) {
    commit("SET_CURRENT_MODE", "OFFLINE_AGENTS");
    dispatch("SHOW_AGENT_LIST", { status: "offline" });
  },
  FETCH_AGENTS_WITH_CHAT_COUNT: async ({ commit }, { email }) => {
    try {
      commit("SET_LOADING_STATE", { type: "agentListLoading", value: true });
      const response = await graph.query({
        query: gql`
          query ($email: String) {
            livechatAPI {
              agentsWithChatCountByDepartment(email: $email)
            }
          }
        `,
        variables: {
          email,
        },
        fetchPolicy: "network-only",
      });
      const agentsWithChatCount = _.get(
        response,
        "data.livechatAPI.agentsWithChatCountByDepartment",
        {}
      );
      commit("SET_AGENTS_WITH_CHAT_COUNT", agentsWithChatCount);
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_STATE", { type: "agentListLoading", value: false });
    }
  },

  FETCH_IN_QUEUE_CHATS: async (
    { rootState, state, commit },
    { transferRedactedId, transferRedactedIdInteractions, limit } = { limit: 10 }
  ) => {
    try {
      commit("SET_LOADING_STATE", { type: "inQueueChatLoading", value: true });

      const response = await graph.query({
        query: PAGINGATE_LIVECHAT_QUERY,
        fetchPolicy: "network-only",
        variables: {
          limit,
          agentIs: "[]",
          resolved: false,
        },
      });

      const { data, count } = _.get(response, "data.livechatAPI.paginateSessions", {
        data: [],
        count: 0,
      });

      const updatedSessionsObject = parseSessions({
        data,
        selectedChatId: _.get(state, "selectedChatId"),
        queueChatObject: rootState.livechat.queueChatObject,
        transferRedactedIdInteractions,
        transferRedactedId,
      });

      commit("SET_IN_QUEUE_CHATS", {
        data: updatedSessionsObject,
        count,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_STATE", { type: "inQueueChatLoading", value: false });
    }
  },
  FETCH_ON_GOING_CHATS: async (
    { rootState, state, commit },
    { transferRedactedId, transferRedactedIdInteractions, limit } = { limit: 10 }
  ) => {
    try {
      commit("SET_LOADING_STATE", { type: "onGoingChatLoading", value: true });

      const response = await graph.query({
        query: PAGINGATE_LIVECHAT_QUERY,
        fetchPolicy: "network-only",
        variables: {
          limit,
          agentIsNot: "[]",
          resolved: false,
        },
      });

      const { data, count } = _.get(response, "data.livechatAPI.paginateSessions", {
        data: [],
        count: 0,
      });

      const updatedSessionsObject = parseSessions({
        data,
        selectedChatId: _.get(state, "selectedChatId"),
        queueChatObject: rootState.livechat.queueChatObject,
        transferRedactedIdInteractions,
        transferRedactedId,
      });

      commit("SET_ON_GOING_CHATS", {
        data: updatedSessionsObject,
        count,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_STATE", { type: "onGoingChatLoading", value: false });
    }
  },
  FETCH_ABANDONED_CHATS: async (
    { rootState, state, commit },
    { transferRedactedId, transferRedactedIdInteractions, limit } = { limit: 10 }
  ) => {
    try {
      commit("SET_LOADING_STATE", { type: "abandonedChatLoading", value: true });

      const response = await graph.query({
        query: PAGINGATE_LIVECHAT_QUERY,
        fetchPolicy: "network-only",
        variables: {
          limit,
          agentIs: "[]",
          resolved: true,
        },
      });

      const { data, count } = _.get(response, "data.livechatAPI.paginateSessions", {
        data: [],
        count: 0,
      });

      const updatedSessionsObject = parseSessions({
        data,
        selectedChatId: _.get(state, "selectedChatId"),
        queueChatObject: rootState.livechat.queueChatObject,
        transferRedactedIdInteractions,
        transferRedactedId,
      });

      commit("SET_ABANDONED_CHATS", {
        data: updatedSessionsObject,
        count,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_STATE", { type: "abandonedChatLoading", value: false });
    }
  },
  FETCH_RESOLVED_CHATS: async (
    { rootState, state, commit },
    { transferRedactedId, transferRedactedIdInteractions, limit } = { limit: 10 }
  ) => {
    try {
      commit("SET_LOADING_STATE", { type: "resolvedChatLoading", value: true });

      const response = await graph.query({
        query: PAGINGATE_LIVECHAT_QUERY,
        fetchPolicy: "network-only",
        variables: {
          limit,
          agentIsNot: "[]",
          resolved: true,
        },
      });

      const { data, count } = _.get(response, "data.livechatAPI.paginateSessions", {
        data: [],
        count: 0,
      });

      const updatedSessionsObject = parseSessions({
        data,
        selectedChatId: _.get(state, "selectedChatId"),
        queueChatObject: rootState.livechat.queueChatObject,
        transferRedactedIdInteractions,
        transferRedactedId,
      });

      commit("SET_RESOLVED_CHATS", {
        data: updatedSessionsObject,
        count,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_STATE", { type: "resolvedChatLoading", value: false });
    }
  },
  FLUSH_SUPERVISOR_DATA: async ({ commit }) => {
    commit("SET_AGENTS_WITH_CHAT_COUNT", []);

    const initialData = { data: [], count: 0 };
    commit("SET_IN_QUEUE_CHATS", initialData);
    commit("SET_ON_GOING_CHATS", initialData);
    commit("SET_ABANDONED_CHATS", initialData);
    commit("SET_RESOLVED_CHATS", initialData);
  },
};

export default actions;
