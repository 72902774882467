<template>
  <div class="node-list-wrapper">
    <el-tabs @tab-click="onTabChange" type="card" v-model="active">
      <el-tab-pane :lazy="true" label="Content" name="content" />
      <el-tab-pane :lazy="true" label="Triggers" name="trigger" />
    </el-tabs>

    <div class="node-list-content">
      <!-- Dialog for uploading node -->
      <!-- FIXME: move the upload dialog and all related functions into new component -->
      <el-dialog
        :title="uploadTitle"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
        :close-on-press-escape="false"
        width="75%"
        v-loading.fullscreen.lock="isDialogLoading"
      >
        <el-upload
          drag
          class="upload-demo"
          action="#"
          :before-upload="beforeUpload"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :file-list="fileList"
        >
          <i class="el-icon-upload upload-icon" />
          <div class="el-upload__text">Drag file / click to upload</div>
          <div slot="tip" class="el-upload__tip">csv file only</div>
        </el-upload>

        <el-divider v-if="uploadedList.length > 0"></el-divider>

        <!-- table for content nodes -->
        <search-table-pagination
          v-if="uploadedList.length > 0 && active === 'content'"
          type="local"
          max-height="600"
          :data="uploadedList"
          :page-sizes="[50, 100]"
          :columns="contentNodeColumns"
          :form-options="formOptions"
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'versionId', order: 'ascending' }"
        >
          <template slot="contentId" slot-scope="contentScope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="contentScope.row.baseId"
                @change="dirty = true"
                @blur="handleEdit(contentScope.row, contentScope.$index)"
                @keyup.esc.native="handleEdit(contentScope.row, contentScope.$index)"
                @keyup.enter.native="handleEdit(contentScope.row, contentScope.$index)"
              />
              <div v-else @click="editContent(contentScope.row)">
                {{ contentScope.row.baseId }}
                <br />
                <sup v-if="contentScope.row.duplicate" class="text-smaller">
                  <em> <i class="el-icon-warning" /> duplicate </em>
                </sup>
              </div>
            </div>
          </template>
          <template slot="versionId" slot-scope="versionScope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="versionScope.row.versionId"
                @change="dirty = true"
                @blur="handleEdit(versionScope.row, versionScope.$index)"
                @keyup.esc.native="handleEdit(versionScope.row, versionScope.$index)"
                @keyup.enter.native="handleEdit(versionScope.row, versionScope.$index)"
              />
              <div v-else @click="editContent(versionScope.row)">
                {{ versionScope.row.versionId }}
                <br />
              </div>
            </div>
          </template>

          <template slot="answers" slot-scope="answersScope">
            <span v-if="answersScope.row.answers.length > 1">
              {{ answersScope.row.answers.length }}
            </span>
            <el-input
              v-else-if="editingContent"
              v-model="answersScope.row.answers[0]"
              @change="dirty = true"
              @blur="handleEdit(answersScope.row, answersScope.$index)"
              @keyup.esc.native="handleEdit(answersScope.row, answersScope.$index)"
              @keyup.enter.native="handleEdit(answersScope.row, answersScope.$index)"
            />
            <div v-else @click="editContent(answersScope.row)">
              {{ answersScope.row.answers[0] }}
            </div>
          </template>
          <template slot="nextEvent" slot-scope="nextEventScope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="nextEventScope.row.next.event"
                @change="dirty = true"
                @blur="handleEdit(nextEventScope.row, nextEventScope.$index)"
                @keyup.esc.native="handleEdit(nextEventScope.row, nextEventScope.$index)"
                @keyup.enter.native="handleEdit(nextEventScope.row, nextEventScope.$index)"
              />
              <div v-else @click="editContent(nextEventScope.row)">
                {{ nextEventScope.row.next.event }}
                <br />
              </div>
            </div>
          </template>
          <template slot="nextData" slot-scope="nextDataScope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="nextDataScope.row.versionId"
                @change="dirty = true"
                @blur="handleEdit(nextDataScope.row, nextDataScope.$index)"
                @keyup.esc.native="handleEdit(nextDataScope.row, nextDataScope.$index)"
                @keyup.enter.native="handleEdit(nextDataScope.row, nextDataScope.$index)"
              />
              <div v-else @click="editContent(nextDataScope.row)">
                {{ nextDataScope.row.next.data }}
                <br />
              </div>
            </div>
          </template>
          <template slot="delay" slot-scope="delayScope">
            <el-input-number
              style="width: auto"
              v-model="delayScope.row.delay"
              size="mini"
              :min="0"
              :max="60"
            ></el-input-number>
          </template>
          <template slot="confirmButton" slot-scope="buttonScope">
            <div>
              <el-checkbox
                @change="toggleConfirm(buttonScope.row, buttonScope.$index)"
                v-model="buttonScope.row.confirmed"
              ></el-checkbox>
            </div>
          </template>
          <template slot="confirmButtonHeader" slot-scope="buttonHeaderScope">
            <el-popover
              placement="bottom"
              title="Upload?"
              width="200"
              trigger="click"
              v-model="isCheckAllPopoverOpen"
            >
              <p style="word-break: break-word">
                Apply upload to
                <a
                  @click.prevent="
                    setUpload(
                      buttonHeaderScope.currentPage,
                      buttonHeaderScope.pageSize,
                      buttonHeaderScope.data,
                      true
                    )
                  "
                  class="has-text-primary"
                  style="font-weight: bold; cursor: pointer"
                  >all</a
                >
                items or items in
                <a
                  @click.prevent="
                    setUpload(
                      buttonHeaderScope.currentPage,
                      buttonHeaderScope.pageSize,
                      buttonHeaderScope.data
                    )
                  "
                  style="font-weight: bold; cursor: pointer"
                  class="has-text-primary"
                >
                  current page
                </a>
                ?
              </p>
              <el-button size="mini" type="text" slot="reference">Upload?</el-button>
            </el-popover>
          </template>
        </search-table-pagination>
        <search-table-pagination
          v-if="uploadedList.length > 0 && active === 'trigger'"
          type="local"
          max-height="600"
          :data="uploadedList"
          :page-sizes="[50, 100]"
          :columns="triggerNodeColumns"
          :form-options="triggerFormOptions"
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'triggerId', order: 'ascending' }"
        >
          <template slot="triggerId" slot-scope="scope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="scope.row.triggerId"
                @change="dirty = true"
                @blur="handleEdit(scope.row, scope.$index)"
                @keyup.esc.native="handleEdit(scope.row, scope.$index)"
                @keyup.enter.native="handleEdit(scope.row, scope.$index)"
              />
              <div v-else @click="editContent(scope.row)">
                {{ scope.row.triggerId }}
                <br />
                <sup v-if="scope.row.duplicate" class="text-smaller">
                  <em> <i class="el-icon-warning" /> duplicate </em>
                </sup>
              </div>
            </div>
          </template>
          <template slot="event" slot-scope="scope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="scope.row.event"
                @change="dirty = true"
                @blur="handleEdit(scope.row, scope.$index)"
                @keyup.esc.native="handleEdit(scope.row, scope.$index)"
                @keyup.enter.native="handleEdit(scope.row, scope.$index)"
              />
              <div v-else @click="editContent(scope.row)">
                {{ scope.row.event }}
                <br />
              </div>
            </div>
          </template>
          <template slot="data" slot-scope="scope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="scope.row.data"
                @change="dirty = true"
                @blur="handleEdit(scope.row, scope.$index)"
                @keyup.esc.native="handleEdit(scope.row, scope.$index)"
                @keyup.enter.native="handleEdit(scope.row, scope.$index)"
              />
              <div v-else @click="editContent(scope.row)">
                {{ scope.row.data }}
                <br />
              </div>
            </div>
          </template>
          <template slot="pattern" slot-scope="scope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="scope.row.pattern"
                @change="dirty = true"
                @blur="handleEdit(scope.row, scope.$index)"
                @keyup.esc.native="handleEdit(scope.row, scope.$index)"
                @keyup.enter.native="handleEdit(scope.row, scope.$index)"
              />
              <div v-else @click="editContent(scope.row)">
                {{ scope.row.pattern }}
                <br />
              </div>
            </div>
          </template>
          <template slot="type" slot-scope="scope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="scope.row.type"
                @change="dirty = true"
                @blur="handleEdit(scope.row, scope.$index)"
                @keyup.esc.native="handleEdit(scope.row, scope.$index)"
                @keyup.enter.native="handleEdit(scope.row, scope.$index)"
              />
              <div v-else @click="editContent(scope.row)">
                {{ scope.row.type }}
                <br />
              </div>
            </div>
          </template>
          <template slot="context" slot-scope="scope">
            <div>
              <el-input
                v-if="editingContent"
                v-model="scope.row.context"
                @change="dirty = true"
                @blur="handleEdit(scope.row, scope.$index)"
                @keyup.esc.native="handleEdit(scope.row, scope.$index)"
                @keyup.enter.native="handleEdit(scope.row, scope.$index)"
              />
              <div v-else @click="editContent(scope.row)">
                {{ scope.row.context }}
                <br />
              </div>
            </div>
          </template>
          <template slot="priority" slot-scope="scope">
            <div>
              <el-input-number
                style="width: auto"
                v-model="scope.row.priority"
                size="mini"
                :min="0"
                :max="99999"
              ></el-input-number>
            </div>
          </template>
          <template slot="confirmButton" slot-scope="scope">
            <div>
              <el-checkbox
                @change="toggleConfirm(scope.row, scope.$index)"
                v-model="scope.row.confirmed"
              ></el-checkbox>
            </div>
          </template>
          <template slot="confirmButtonHeader" slot-scope="buttonHeaderScope">
            <el-popover
              placement="bottom"
              title="Upload?"
              width="200"
              trigger="click"
              v-model="isCheckAllPopoverOpen"
            >
              <p style="word-break: break-word">
                Apply upload to
                <a
                  @click.prevent="
                    setUpload(
                      buttonHeaderScope.currentPage,
                      buttonHeaderScope.pageSize,
                      buttonHeaderScope.data,
                      true
                    )
                  "
                  style="font-weight: bold; cursor: pointer"
                  class="has-text-primary"
                >
                  all
                </a>
                items or items in
                <a
                  @click.prevent="
                    setUpload(
                      buttonHeaderScope.currentPage,
                      buttonHeaderScope.pageSize,
                      buttonHeaderScope.data
                    )
                  "
                  style="font-weight: bold; cursor: pointer"
                  class="has-text-primary"
                >
                  current page
                </a>
                ?
              </p>
              <el-button size="mini" type="text" slot="reference">Upload?</el-button>
            </el-popover>
          </template>
        </search-table-pagination>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="handleClose(false)">Cancel</el-button>
          <el-button
            type="primary"
            :disabled="this.uploadedList.length === 0"
            @click="upload"
            size="mini"
          >
            Upload
          </el-button>
        </span>
      </el-dialog>

      <div class="items-group" style="text-align: right; padding: 10px 0">
        <el-tooltip class="item" placement="bottom" content="Filter error nodes">
          <el-button
            :type="activeErrorOnly ? 'danger' : ''"
            icon="el-icon-warning"
            size="mini"
            @click="filterList()"
          />
        </el-tooltip>

        <!-- Download nodes -->
        <el-tooltip class="item" placement="bottom" content="Download">
          <el-button size="mini" icon="el-icon-download" @click="exportCT" />
        </el-tooltip>

        <!-- Upload nodes -->
        <el-tooltip class="item" placement="bottom" content="Upload">
          <el-button size="mini" icon="el-icon-upload" @click="dialogVisible = true" />
        </el-tooltip>

        <!-- Creating new node -->
        <el-popover v-model="showNewNodePopover" placement="left" width="344">
          <!-- What will be shown in the popover -->
          <el-input
            v-model.trim="newNodeName"
            autofocus
            @keyup.enter.native="create"
            @keyup.esc.native="showNewNodePopover = false"
          >
            <template slot="prepend">{{ active }} name</template>
            <el-button slot="append" icon="el-icon-check" @click="create" />
          </el-input>

          <!-- Add new content -->
          <el-tooltip slot="reference" class="item" placement="bottom" content="Add new content">
            <el-button size="mini" icon="el-icon-plus" />
          </el-tooltip>
        </el-popover>
      </div>

      <div style="padding-bottom: 20px">
        <el-input v-model="query" placeholder="Search" suffix-icon="el-icon-search" />
      </div>

      <!-- Display of the nodes via a tree -->
      <el-tree
        v-loading="loadingNodes"
        ref="list"
        node-key="id"
        class="node-list-tree"
        :render-after-expand="true"
        :data="nodes"
        :filter-node-method="filter"
        :props="defaultProps"
        :default-expanded-keys="defaultExpandedNodeList"
        @node-collapse="handleCollapseNodeList"
        @node-expand="handleExpandNodeList"
        @node-click="select"
      >
        <span
          slot-scope="{ node }"
          :class="{
            new: node.label.includes('*') ? true : false,
            'el-tree-node__label': true,
          }"
        >
          <i class="el-icon-warning tree-invalid-icon" v-if="node.data.invalid"></i>
          <span
            :class="{
              tree_invalid: node.data.invalid,
            }"
          >
            {{ node.label }}
          </span>
          <!-- is_default mean this node registered at module content_map -->
          <el-tag
            style="margin-left: 5px; font-size: 10px"
            v-if="node.level === 2 && node.data.is_default"
            size="mini"
          >
            system
          </el-tag>

          <el-tag
            style="margin-left: 5px; font-size: 10px"
            v-if="node.level === 2 && node.data.isStickyMenu"
            size="mini"
            type="success"
          >
            sticky menu
          </el-tag>

          <el-tag
            style="margin-left: 5px; font-size: 10px"
            v-if="node.level === 1 && node.data.childrenHasStickyMenu && !node.expanded"
            size="mini"
            type="success"
          >
            sticky menu
          </el-tag>
        </span>
      </el-tree>

      <br />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Papa from "papaparse";
import SearchTablePagination from "./SearchTablePagination";
import _ from "lodash";
import XLSX from "xlsx";
import {
  checkHasLinks,
  isValidContentName,
  validateButton,
  validateContent,
  validateLinks,
  validateQuickReplies,
} from "@/helperMethods/task_management/content";

import {
  checkIdenticalTrigger,
  checkWildCardTrigger,
} from "@/helperMethods/task_management/trigger";
import { updateContentNodeObject } from "@/helperMethods/editor/index.ts";
import { formatNotificationMessage, isSelf } from "@/helperMethods/editor/nodeList.ts";
import { mapGetters } from "vuex";
import { gql } from "@apollo/client/core";
import * as Sentry from "@sentry/browser";
import { processContentUpload, processTriggerUpload } from "@/helperMethods/editor/nodeList";
import { updateUrlQuery } from "@/helperMethods/util";
import { getTriggerExportData, getContentExportData } from "./helpers";

export default {
  name: "NodeList",
  components: { SearchTablePagination },
  data() {
    return {
      triggerNodes: [],
      loadingNodes: false,
      query: _.get(this.$route, "query.search") || "",
      newNodeName: "",
      activeErrorOnly: false,
      showNewNodePopover: false,
      dialogVisible: false,
      defaultProps: {
        label: "label",
        children: "children",
        invalid: false,
      },
      formOptions: {
        inline: true,
        fuzzy: true,
        submitBtnText: "Search",
        forms: [{ prop: "baseId", label: "Content ID" }],
      },
      triggerFormOptions: {
        inline: true,
        fuzzy: true,
        submitBtnText: "Search",
        forms: [{ prop: "triggerId", label: "Trigger ID" }],
      },
      triggerNodeColumns: [
        {
          prop: "triggerId",
          label: "Trigger ID",
          minWidth: 80,
          slotName: "triggerId",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "event",
          label: "Event",
          minWidth: 80,
          slotName: "event",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "data",
          label: "Data",
          minWidth: 80,
          slotName: "data",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "pattern",
          label: "Pattern",
          minWidth: 80,
          slotName: "pattern",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "type",
          label: "Type",
          minWidth: 80,
          slotName: "type",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "context",
          label: "Context",
          minWidth: 80,
          slotName: "context",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "priority",
          label: "Priority",
          minWidth: 80,
          slotName: "priority",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          align: "center",
          label: "Positive Tests",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "positiveTest", []).length;
          },
        },
        {
          align: "center",
          label: "Negative Tests",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "negativeTest", []).length;
          },
        },
        {
          label: "Upload?",
          minWidth: 40,
          slotName: "confirmButton",
          headerSlotName: "confirmButtonHeader",
        },
      ],
      contentNodeColumns: [
        {
          prop: "baseId",
          label: "Content ID",
          minWidth: 80,
          slotName: "contentId",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "versionId",
          label: "Version ID",
          minWidth: 80,
          slotName: "versionId",
          sortable: "",
          sortBy: "duplicate",
        },
        {
          prop: "answers",
          label: "Answer",
          minWidth: 180,
          slotName: "answers",
        },
        { prop: "delay", label: "Delay", minWidth: 60, slotName: "delay" },
        {
          label: "Next Event",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "next.event", "N/A");
          },
        },
        {
          label: "Next Data",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "next.data", "N/A");
          },
        },
        {
          align: "center",
          label: "Buttons",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "buttons", []).length;
          },
        },
        {
          align: "center",
          label: "Quick Replies",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "quickReplies", []).length;
          },
        },
        {
          align: "center",
          label: "Conditions",
          minWidth: 60,
          render: (row) => {
            return _.get(row, "conditions", []).length;
          },
        },
        {
          label: "Upload?",
          minWidth: 40,
          slotName: "confirmButton",
          headerSlotName: "confirmButtonHeader",
        },
      ],
      contentNodes: [],
      uploadedList: [],
      fileList: [],
      editingContent: false,
      dirty: false,
      defaultExpandedNodeList: [],
      isDialogLoading: false,
      isCheckAllPopoverOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      "updateContentNode",
      "refetchContentNodes",
      "makerCheckerEnabled",
      "triggerDiff",
    ]),
    triggers() {
      return this.$store.state.nodes.trigger;
    },
    active: {
      get() {
        return this.$store.state.activeNodeType;
      },
      set(value) {
        this.$store.state.activeNodeType = value;
      },
    },
    uploadTitle() {
      if (this.active === "content") return "Upload Content";
      return "Upload Triggers";
    },
    isUploadedContentNodes: {
      get() {
        return this.$store.state.editor.isUploadedContentNodes;
      },
      set(value) {
        Vue.set(this.$store.state.editor, "isUploadedContentNodes", value);
      },
    },
    ready() {
      return Object.keys(this.$store.state.nodes.content).length > 0;
    },
    nodes() {
      if (this.active === "content") {
        // Content nodes
        return this.contentNodes;
      } else if (this.active === "trigger") {
        // Trigger nodes
        if (this.$refs.list) {
          Object.keys(this.$refs.list.store.nodesMap).map((key, index) => {
            if (this.defaultExpandedNodeList.indexOf(key) !== -1) {
              // get nodes to expand
              this.$refs.list.store.nodesMap[key].expanded = true;
            }
          });
        }
        return this.triggerNodes;
      } else {
        return [];
      }
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
  },
  methods: {
    onTabChange() {
      updateUrlQuery({
        editorTab: this.active,
        selectedNode: null,
      });
    },
    getTriggerNodes() {
      const trigger = this.$store.state.nodes.trigger;

      if (this.ready) {
        const sortedTriggerIds = Object.keys(trigger).sort();

        const mappedTriggerIds = sortedTriggerIds.map((id) => {
          let parts = id.split("_");
          let mod = "";
          let label = "";

          if (parts.length > 1) {
            mod = parts[0];
            label = parts.slice(1).join(" ");
          } else {
            mod = parts[0];
            label = parts[0];
          }

          const node = checkIdenticalTrigger(id, this.triggerDiff);
          const wildCard = checkWildCardTrigger(id);
          const searchableText = `${JSON.stringify(this.$store.state.nodes.trigger[id])
            .replace(/[:"{},$]/g, "")
            .toLowerCase()}${this.$store.state.nodes.trigger[id].pattern}`;
          return {
            module: mod,
            id,
            searchableText,
            label: label,
            type: "trigger",
            invalid: node || wildCard ? true : false,
          };
        });

        const filteredTriggersBySearchQuery = mappedTriggerIds.filter(
          (node) =>
            node.searchableText.toLowerCase().includes(this.query.toLowerCase()) ||
            node.id.toLowerCase().includes(this.query.toLowerCase())
        );

        const filteredTriggersByValidity = filteredTriggersBySearchQuery.filter((node) => {
          return (this.activeErrorOnly && node.invalid) || !this.activeErrorOnly;
        });

        const convertedTriggers = filteredTriggersByValidity.reduce((modules, item) => {
          let group = modules.find((m) => m.id === item.module);

          if (group) {
            group.children.push(item);
            group.children.forEach((ele) => {
              if (ele.invalid) {
                group["invalid"] = true;
              }
            });
          } else {
            modules.push({
              id: item.module,
              label: item.module,
              children: [item],
              invalid: item.invalid,
            });
          }

          return modules;
        }, []);
        return convertedTriggers;
      } else {
        return [];
      }
    },
    getContentNodes() {
      if (this.ready) {
        this.loadingNodes = true;
        let contentNodes = this.$store.state.nodes.content;

        let list = _.chain(Object.keys(contentNodes))
          .sort()
          .map((id) => {
            let parts = id.split("_");
            let mod = "";
            let label = "";

            if (parts.length > 1) {
              mod = parts[0];
              label = parts.slice(1).join(" ");
            } else {
              mod = parts[0];
              label = parts[0];
            }

            let contentNode = contentNodes[id];
            let searchableText = JSON.stringify(contentNode);

            const contentNodeDeletedOrDoesNotExists = !contentNode;
            if (contentNodeDeletedOrDoesNotExists) {
              return;
            }

            //Remove Reserve words
            searchableText = searchableText.replace(/[:"{},$]/g, "").toLowerCase();

            if (contentNode.new) {
              label += "*";
            }

            let invalid = false;
            const textIsEmpty = validateContent(id);
            const invalidButton = validateButton(id);
            const invalidQuickReplies = validateQuickReplies(id);
            const invalidLinks = validateLinks(id);
            const hasLinks = checkHasLinks(id);

            const invalidButtonQuickRepliesOrLinks =
              invalidButton || invalidQuickReplies || invalidLinks;

            // If there's empty text or no links, always show warning regardless of buttons/QR
            const noTextOrLinks = textIsEmpty && !hasLinks;
            if (invalidButtonQuickRepliesOrLinks || noTextOrLinks) {
              invalid = true;
            }

            const isStickyMenu = _.has(this.modules.webchat.stickyMenu, id);

            return {
              module: mod,
              id,
              searchableText,
              label: label,
              type: "content",
              changeStatus: "new",
              invalid: invalid,
              is_default: contentNode.is_default, // node registered at module content_map
              isStickyMenu,
            };
          })
          .compact()
          .filter(
            (node) =>
              // Search filter by content node id/name
              node.searchableText.toLowerCase().includes(this.query.toLowerCase()) ||
              node.id.toLowerCase().includes(this.query.toLowerCase())
          )
          .value();

        // Parse to format required for el-tree
        const finalResult = list
          .reduce((modules, item) => {
            let group = modules.find((m) => m.id === item.module);

            if (group) {
              // If group exist, it will push new items in as children
              group.children.push(item);
              group["childrenHasStickyMenu"] = false;

              group.children.forEach((ele) => {
                if (ele.invalid) {
                  group["invalid"] = true;
                }
                if (ele.isStickyMenu) {
                  group["childrenHasStickyMenu"] = true;
                }
              });
            } else {
              let childrenHasStickyMenu = false;
              if (item.isStickyMenu) childrenHasStickyMenu = true;
              // id, label, children
              modules.push({
                id: item.module,
                label: item.module,
                children: [item],
                invalid: item.invalid,
                childrenHasStickyMenu,
              });
            }
            return modules;
          }, [])
          .filter((node) => {
            return (this.activeErrorOnly && node.invalid) || !this.activeErrorOnly;
          });
        this.loadingNodes = false;
        return finalResult;
      } else {
        return [];
      }
    },
    filterList() {
      this.activeErrorOnly = !this.activeErrorOnly;
      this.contentNodes = this.getContentNodes();
    },
    handleExpandNodeList(node, treenode) {
      this.defaultExpandedNodeList.push(node.id);
    },
    handleCollapseNodeList(node, treenode) {
      this.defaultExpandedNodeList = this.defaultExpandedNodeList.filter((id) => id !== node.id);
    },
    exportCT() {
      if (this.active === "content") {
        const toExport = getContentExportData(this.$store.state.nodes.content);
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(toExport);
        XLSX.utils.book_append_sheet(wb, ws, "content");
        const filename = this.$exportFilename("content", "csv");
        XLSX.writeFile(wb, filename, {});
      } else if (this.active === "trigger") {
        const toExport = getTriggerExportData(this.$store.state.nodes.trigger);
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(toExport);
        XLSX.utils.book_append_sheet(wb, ws, "trigger");
        const filename = this.$exportFilename("trigger", "csv");
        XLSX.writeFile(wb, filename, {});
      }
    },
    checkDuplication(row) {
      if (this.active === "trigger") {
        const existingTriggers = this.$store.state.nodes.trigger;
        return !!existingTriggers[row.triggerId];
      }
    },
    handleEdit(row, index) {
      this.dirty = false;
      this.editingContent = false;
      const hasDuplicates = this.checkDuplication(row);
      if (hasDuplicates) {
        row.duplicate = true;
      } else {
        row.duplicate = false;
      }

      this.uploadedList[index] = row;
    },
    editContent(row) {
      this.editingContent = true;
    },
    tableRowClassName({ row }, rowIndex) {
      if (row.duplicate) {
        return row.confirmed ? "confirmed-row" : "warning-row";
      }
      return "";
    },
    toggleConfirm(row, index) {
      this.uploadedList[index].confirmed = row.confirmed;
    },
    beforeUpload(file) {
      const isCSV =
        (file.type === "text/csv" || file.type === "application/vnd.ms-excel") &&
        file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess(file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.handleRemove();

      if (done) done();
    },
    handleRemove() {
      this.fileList = [];
      this.uploadedList = [];
    },
    handleSuccess(file) {
      const reader = new FileReader();
      const self = this;
      reader.readAsText(file);
      reader.onload = (e) => {
        Papa.parse(e.target.result, {
          header: true,
          complete: ({ data }) => {
            if (data.length > 0) {
              const isUploadedContentNode = !!data[0]["Content ID"];
              self.isUploadedContentNodes = isUploadedContentNode;
            } else {
              self.$message({
                type: "info",
                message: `No Contents or Triggers Found`,
              });
            }

            this.uploadedList = [];
            if (self.isUploadedContentNodes) {
              const existingContentNodes = this.$store.state.nodes.content;
              this.uploadedList = processContentUpload(data, existingContentNodes);
            } else {
              const existingTriggerNodes = this.$store.state.nodes.trigger;
              this.uploadedList = processTriggerUpload(data, existingTriggerNodes);
            }
          },
        });
      };
      this.fileList = [...this.fileList, file];
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    select({ type, id }) {
      if (type && id) {
        this.$store.dispatch("SELECT_NODE", { type, id });
      }
    },
    filter(idList, node) {
      if (!this.query) {
        return true;
      } else {
        return idList.includes(node.id);
      }
    },
    upload() {
      this.isDialogLoading = true;
      _.delay(() => {
        const objectBeingImported = this.active === "content" ? "content" : "trigger(s)";
        const contentLength = this.uploadedList.length;
        const noUploadedContent = contentLength === 0;
        if (noUploadedContent) {
          this.$notify.info({
            title: `No ${objectBeingImported} found`,
            message: `No upload was performed`,
            position: "bottom-right",
          });
          return;
        }

        const confirmedUploadedNodes = _.filter(this.uploadedList, (obj) => obj.confirmed);

        if (_.isEmpty(confirmedUploadedNodes)) {
          this.$notify.info({
            title: `No ${objectBeingImported} selected`,
            message: `Please select at least 1 ${objectBeingImported} to import`,
            position: "bottom-right",
          });
          return;
        } else {
          const firstNode = _.first(confirmedUploadedNodes);
          let selectNode = "";

          if (this.isUploadedContentNodes) {
            for (const node of confirmedUploadedNodes) {
              const { buttons, quickReplies, answers, next, delay, conditions, baseId, versionId } =
                node;

              const nodeId = _.compact([baseId, versionId]).join(":");
              const commitPayload = {
                id: nodeId,
                node: {
                  content: {
                    text: answers,
                    buttons,
                  },
                  new: true,
                  quickReplies,
                  department: node.department,
                  conditions,
                },
              };

              const parsedDelay = Number.parseInt(delay);
              if (!isNaN(parsedDelay)) {
                commitPayload.node.delay = parsedDelay;
              }
              if (next) {
                commitPayload.node.next = next;
              }

              if (this.makerCheckerEnabled) {
                const oldNode = this.$store.state.nodes.content[nodeId];
                this.$store.commit("APPEND_OLD_NODE_BY_TYPE", {
                  type: "content",
                  oldNode,
                });
              }

              this.$store.commit("CREATE_CONTENT_NODE", commitPayload);
              this.$store.commit("SET_UPLOAD_CONTENT_SUCCESS", true);
              this.$store.commit("SET_CHANGE_CONTENT_NODE", true);
            }

            selectNode = _.compact([firstNode.baseId, firstNode.versionId]).join(":");
          } else {
            // FIXME: else if = trigger
            for (const trigger of confirmedUploadedNodes) {
              const { triggerId, event, data, context, type, pattern } = trigger;
              const positiveTests = _.get(trigger, "positiveTest", []);
              const negativeTests = _.get(trigger, "negativeTest", []);
              const priority = _.get(trigger, "priority", "0");
              const parsedPriority = Number.parseInt(priority);
              const commitPayload = {
                id: triggerId,
                type: "trigger",
                node: {
                  event,
                  data,
                  context,
                  type,
                  pattern,
                  priority: parsedPriority,
                  tests: {
                    positive: positiveTests,
                    negative: negativeTests,
                  },
                  new: true,
                },
              };
              if (this.makerCheckerEnabled) {
                const oldNode = this.$store.state.nodes.trigger[trigger.id];
                this.$store.commit("APPEND_OLD_NODE_BY_TYPE", {
                  type: "trigger",
                  oldNode,
                });
              }

              this.$store.dispatch("CREATE_TRIGGER_NODE", commitPayload);
              this.$store.commit("SET_UPLOAD_CONTENT_SUCCESS", true);
            }

            selectNode = firstNode.triggerId;
          }

          this.$notify.success({
            title: "Successful Upload",
            message: `Uploaded ${confirmedUploadedNodes.length} ${objectBeingImported}. Please click save to save all trigger nodes`,
            position: "bottom-right",
          });

          this.$emit("select", {
            type: this.active,
            id: selectNode,
          });

          this.handleClose(false);
          this.isDialogLoading = false;
        }
      }, 200);
    },
    create() {
      if (this.newNodeName) {
        if (this.active === "content") {
          if (!isValidContentName(this.newNodeName))
            return this.$message({
              type: "error",
              message: `Invalid node name`,
            });

          this.$store.commit("CREATE_CONTENT_NODE", { id: this.newNodeName });
          this.$store.commit("SET_CHANGE_CONTENT_NODE", true);
        } else if (this.active === "trigger") {
          this.$store.dispatch("CREATE_TRIGGER_NODE", { id: this.newNodeName });
        } else if (this.active === "condition") {
          this.$store.commit("CREATE_CONDITION_NODE", { id: this.newNodeName });
        }
        this.$emit("select", { type: this.active, id: this.newNodeName });

        this.$message({
          type: "success",
          message: `Created ${this.newNodeName} ${this.active}`,
        });
      }

      this.newNodeName = "";
      this.showNewNodePopover = false;
    },
    setUpload(currentPage, pageSize, data, isCheckAll = false) {
      this.isDialogLoading = true;
      _.delay(() => {
        const isAllConfirmed = _.every(data, (obj) => obj.confirmed);
        if (isCheckAll) {
          const updatedNodes = _.chain(this.uploadedList)
            .cloneDeep()
            .map((item) => {
              return {
                ...item,
                confirmed: isAllConfirmed ? false : true,
              };
            })
            .value();
          this.uploadedList = updatedNodes;
        } else {
          const start = (currentPage - 1) * pageSize;
          const end = currentPage * pageSize;
          const updatedNodes = _.chain(this.uploadedList)
            .slice(start, end)
            .map((ele) => {
              return {
                ...ele,
                confirmed: !isAllConfirmed,
              };
            })
            .value();
          this.uploadedList.splice(start, end - start, ...updatedNodes);
        }

        this.isDialogLoading = false;
        this.isCheckAllPopoverOpen = false;
      }, 200);
    },
  },

  mounted() {
    this.$store.dispatch("UPDATE_TRIGGER_DIFF");
    this.contentNodes = this.getContentNodes();
    this.triggerNodes = this.getTriggerNodes();

    const activeTab = _.get(this.$route, "query.editorTab");
    const selectedNode = _.get(this.$route, "query.selectedNode");
    if (_.includes(["content", "trigger"], activeTab)) {
      this.active = activeTab;
    }
    if (selectedNode) {
      this.select({ type: activeTab, id: selectedNode });
      this.defaultExpandedNodeList.push(selectedNode);
    }
  },

  watch: {
    refetchContentNodes: function () {
      this.$store.commit("SET_REFETCH_CONTENT_NODE", false);
      this.contentNodes = this.getContentNodes();
    },
    updateContentNode: function (val) {
      if (val) {
        this.$store.commit("SET_CHANGE_CONTENT_NODE", false);
      }
      this.contentNodes = this.getContentNodes();
    },
    query: _.debounce(function () {
      if (this.active === "content") {
        this.contentNodes = this.getContentNodes();
      } else if (this.active === "trigger") {
        this.triggerNodes = this.getTriggerNodes();
      }
    }, 300),
    triggers: function () {
      this.triggerNodes = this.getTriggerNodes();
    },
  },
  apollo: {
    contentNodeList() {
      // change to contentNodes, once it is stable
      return {
        query: gql`
          query {
            Bot {
              content: contentNodes
            }
          }
        `,
        fetchPolicy: "no-cache",
        update: (data) => {
          const contentNodes = _.get(data, "Bot.content");
          this.$set(this.$store.state.nodes, "content", contentNodes);
          return contentNodes;
        },
        subscribeToMore: {
          document: gql`
            subscription {
              content: contentNodeChanged
            }
          `,
          updateQuery: (previousResult, { subscriptionData }) => {
            // const originalContentNodes = _.get(previousResult, "Bot.content");
            const originalContentNodes = _.get(this.$store.state.nodes, "content");

            const contentNodeObjects = _.get(subscriptionData, "data.content");
            const payloadEmpty = _.get(contentNodeObjects, "length", 0) === 0;
            if (!contentNodeObjects || payloadEmpty) {
              console.log(`contentNodeObjects is empty`);
              return;
            }

            // FIXME: console logs to be removed once dev is completed
            console.log("Content nodes update coming in: ", contentNodeObjects);
            const { updatedContentNodes } = updateContentNodeObject(
              originalContentNodes,
              contentNodeObjects
            );

            this.$set(this.$store.state.nodes, "content", updatedContentNodes);
            this.contentNodes = this.getContentNodes();

            const author = _.get(contentNodeObjects[0], "author");
            const isNotSelf = !isSelf(author);

            if (isNotSelf) {
              let message = formatNotificationMessage(contentNodeObjects);

              const h = this.$createElement;
              const finalizedMessage = h("i", { style: "color: teal" }, message);

              this.$notify.info({
                title: "Node Update",
                message: finalizedMessage,
                position: "bottom-right",
              });
            }

            return updatedContentNodes; // Not needed
          },
        },
        error(error) {
          Sentry.captureException(error);
          Sentry.captureMessage("contentNodeList encountered error");
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

.tree-invalid-icon {
  color: $color-danger;
  margin: 0 5px 0 0;
}

.tree_invalid {
  color: $color-danger;
}

.new {
  ::v-deep {
    .el-tree-node__label {
      color: $color-success;
    }
  }
}

.warning-row {
  ::v-deep {
    .el-table__row {
      background: oldlace;
    }
  }
}

.confirmed-row {
  ::v-deep {
    .el-table__row {
      background: $color-light;
    }
  }
}

.node-list-content {
  position: relative;
  padding: 0 10px;
  border-left: 1px solid $color-light;
  border-right: 1px solid $color-light;
  border-bottom: 1px solid $color-light;
}

.node-list-tree {
  height: 72.5vh;
  overflow: auto;
  @media screen and (max-width: 1440px) {
    height: 71vh;
  }
}

.node-list-wrapper {
  position: relative;
  margin: 0 15px 0 0;
  overflow: hidden;

  ::v-deep {
    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__content {
      padding: 0 !important;
    }

    .el-switch__label--right.is-active {
      color: $color-success;
    }

    .el-upload,
    .el-upload-dragger {
      width: 100%;
    }

    .el-table .caret-wrapper {
      display: inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 34px;
      width: 24px;
      vertical-align: middle;
      cursor: pointer;
      overflow: initial;
      position: relative;
    }

    .el-table .sort-caret {
      width: 0;
      height: 0;
      border: 5px solid transparent;
      position: absolute;
      left: 7px;
    }

    .el-table .ascending .sort-caret.ascending {
      border-bottom-color: $color-info;
    }

    .el-table .sort-caret.ascending {
      border-bottom-color: $color-lightgrey;
      bottom: 7px;
    }

    .el-table .descending .sort-caret.descending {
      border-top-color: $color-info;
    }

    .el-table .sort-caret.descending {
      border-top-color: $color-lightgrey;
      top: 14px;
    }

    .el-tree-node__content {
      height: auto;
      white-space: initial;
    }
  }
}
</style>
