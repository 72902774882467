<template>
  <el-card>
    <!-- HEADER -->
    <div slot="header" class="clearfix">
      <h3 class="header-title">Developer</h3>
      <br />
      <small>Data updates occur every 5-10 minutes.</small>
    </div>

    <!-- DATE FILTER -->
    <el-row type="flex" align="middle">
      <el-col class="date-picker">
        <small class="bold-text">Filter by date range</small>
        <el-date-picker
          :disabled="loading"
          v-model="searchDateRange"
          type="daterange"
          size="mini"
          format="dd-MM-yyyy"
          class="date-picker-input"
          align="right"
          @change="handleDateRangeChange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-col>
      <!-- RIGHT BUTTONS -->
      <el-col v-if="$store.getters.isShowAdvanced">
        <div style="float: right">
          <el-button
            type="primary"
            plain
            size="mini"
            :disabled="loading"
            icon="el-icon-refresh"
            @click="forceSync"
            >Force sync
          </el-button>
          <el-button
            type="primary"
            size="mini"
            :disabled="loading"
            icon="el-icon-refresh"
            @click="fetchData"
            >Fetch updates
          </el-button>
        </div>
      </el-col>
    </el-row>
    <br />

    <!-- PAGINATION & NO OF RECORDS -->
    <div class="block">
      <el-pagination
        :disabled="loading"
        background
        small
        layout="prev, pager, next"
        :pageSize="pageSize"
        :current-page.sync="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
        @size-change="handleSizeChange"
        style="display: inline-block"
      ></el-pagination>

      <div style="display: inline-block">
        <span>{{ noOfItems }} records</span>
      </div>
    </div>

    <!-- DATA TABLE -->
    <div style="margin-top: 20px">
      <el-table
        size="mini"
        v-loading="loading"
        element-loading-text="Loading data.."
        :data="pagedData"
        style="width: 100%"
        :row-class-name="rowClassName"
        @filter-change="handleFilterChange"
      >
        <!-- TIME -->
        <el-table-column label="Time" min-width="175">
          <template slot-scope="scope">
            <strong>{{ scope.row.createdAt | fromNow }}</strong>
            <br />
            <small>{{ formatDate(scope.row.createdAt) }}</small>
          </template>
        </el-table-column>
        <!-- TYPE -->
        <el-table-column
          prop="type"
          label="Type"
          width="125"
          :filters="[
            { text: 'Incoming', value: 'INCOMING' },
            { text: 'Outgoing', value: 'OUTGOING' },
          ]"
          column-key="type"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row?.type.toLowerCase() === 'incoming' ? 'info' : 'warning'"
              size="small"
              effect="light"
            >
              {{ scope.row.type }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- URL -->
        <el-table-column prop="url" label="URL" min-width="250" />
        <!-- RESPONSE CODE -->
        <el-table-column
          prop="responseCode"
          label="Response"
          width="100"
          :filters="[
            { text: '200 (OK)', value: '200' },
            { text: '400 (Bad request)', value: '400' },
            { text: '401 (Unauthorized)', value: '401' },
            { text: '403 (Forbidden)', value: '403' },
            { text: '404 (Not found)', value: '404' },
            { text: '409 (Request already processed)', value: '409' },
            { text: '429 (Too many requests)', value: '429' },
            { text: '500 (Internal server error)', value: '500' },
          ]"
          column-key="responseCode"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row?.responseCode === 200 ? 'success' : 'danger'"
              size="small"
              effect="light"
            >
              {{ scope.row.responseCode }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- EVENT NAME -->
        <el-table-column
          prop="eventName"
          label="Event name"
          min-width="250"
          :filters="[
            { text: 'bot:beforeTextHandler', value: 'bot:BeforeTextHandler' },
            {
              text: 'handover:afterUserRequestedLivechat',
              value: 'handover:AfterUserRequestedLivechat',
            },
            {
              text: 'handover:afterLivechatSessionEnded',
              value: 'handover:AfterLivechatSessionEnded',
            },
            {
              text: 'handover:afterAgentJoinedLivechat',
              value: 'handover:AfterAgentJoinedLivechat',
            },
            { text: 'handover:agentChangedStatus', value: 'handover:AgentChangedStatus' },
            {
              text: 'handover:afterAgentAcceptedInvitation',
              value: 'handover:AfterAgentAcceptedInvitation',
            },
            {
              text: 'handover:afterLivechatSessionEscalated',
              value: 'handover:AfterLivechatSessionEscalated',
            },
          ]"
          column-key="eventName"
        />
        <!-- API KEY -->
        <el-table-column prop="apiKey" label="API Key" min-width="250" />
        <!-- API KEY NAME -->
        <el-table-column prop="apiKeyName" label="API Key Name" min-width="250" />
        <!-- VIEW DETAIL BUTTON -->
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-tooltip class="box-item" effect="dark" content="View detail" placement="top-start">
              <el-button
                size="mini"
                type="primary"
                plain
                icon="el-icon-view"
                @click="openDialog(scope.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- RESULT JSON -->
    <el-dialog title="Result" :visible.sync="dialogVisible" width="700px" v-if="logDetail">
      <JSONEditor ref="jsonEditor" v-model="logDetail" />
    </el-dialog>
  </el-card>
</template>

<script>
import moment from "moment";
import { gql } from "@apollo/client/core";
import _ from "lodash";
import { flatten } from "@/helpers/flatten";
import { defaultPickerOptions } from "@/helperMethods/util";
import { isDateRangeValid } from "@/components/LiveChat/helpers/filters";
import JSONEditor from "@/components/JSONEditor";

const DAYS_TO_SUBTRACT = 7;

export default {
  components: { JSONEditor },
  data() {
    return {
      loading: true,
      pagedData: [],
      totalRecordCount: 0,
      pageSize: 25,
      currentPage: 1,
      searchDateRange: [moment().subtract(DAYS_TO_SUBTRACT, "days").toDate(), moment().toDate()],
      pickerOptions: {
        shortcuts: _.take(defaultPickerOptions?.shortcuts, 3),
      },
      filter: {},
      dialogVisible: false,
      logDetail: null,
    };
  },
  computed: {
    noOfItems() {
      return this.totalRecordCount || 0;
    },
    startDate() {
      return this.searchDateRange[0];
    },
    endDate() {
      return this.searchDateRange[1];
    },
  },
  methods: {
    flatten,
    isDateRangeValid,
    fetchData() {
      if (!this.validate()) return false;
      this.loading = true;

      this.$apollo.queries.paginateDevCenterLogs.refetch({
        fromDate: this.startDate,
        toDate: this.endDate,
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        sort: "desc",
        filter: this.filter,
      });
    },
    rowClassName({ row }) {
      return row.responseCode !== 200 ? "warning-row" : "";
    },
    formatDate(date) {
      return moment(date).local().format("D MMM YY, h:mm:ss.SSS A ");
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.fetchData();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    handleDateRangeChange() {
      this.currentPage = 1;
      this.fetchData();
    },
    handleFilterChange(updatedFilter) {
      this.filter = _.pickBy(
        {
          ...this.filter,
          ...updatedFilter,
        },
        (value) => (Array.isArray(value) ? value.length > 0 : true)
      );
      this.currentPage = 1;
      this.fetchData();
    },
    validate() {
      if (!this.isDateRangeValid({ startDate: this.startDate, endDate: this.endDate })) {
        this.$notify.warning({
          title: "Info",
          position: "bottom-right",
          message: `Date range should not be greater than 7 days`,
        });

        return false;
      }

      return true;
    },
    openDialog(row) {
      this.logDetail = null;

      this.$apollo
        .query({
          query: gql`
            query Logs($id: String!) {
              devCenterAPI {
                getLogDetailById(id: $id) {
                  data
                }
              }
            }
          `,
          variables: {
            id: row.id,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          const data = _.get(response, "data.devCenterAPI.getLogDetailById.data", {});
          this.logDetail = {
            ...row,
            data,
          };
        })
        .catch((error) => {
          this.logDetail = error;
          console.error(error);
        })
        .finally(() => {
          this.dialogVisible = true;
        });
    },
    async forceSync() {
      try {
        this.loading = true;

        const response = await this.$apollo.query({
          query: gql`
            query {
              devCenterAPI {
                forceSync
              }
            }
          `,
          fetchPolicy: "no-cache",
        });

        const isSuccess = _.get(response, "data.devCenterAPI.forceSync", false);
        if (isSuccess) {
          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message:
              "Synchronization has started. Please wait a couple of minutes before pressing 'Fetch Updates'.",
          });
        } else {
          this.$notify.warning({
            title: "In progress",
            position: "bottom-right",
            message: "Synchronization is already in progress",
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message || "Error fetching data",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  apollo: {
    paginateDevCenterLogs() {
      const { startDate, endDate, pageSize, currentPage, filter } = this;

      return {
        query: gql`
          query Logs(
            $fromDate: String!
            $toDate: String!
            $limit: Int = 100
            $offset: Int = 0
            $sort: String = "desc"
            $filter: JSON
          ) {
            devCenterAPI {
              paginateLogs(
                fromDate: $fromDate
                toDate: $toDate
                limit: $limit
                offset: $offset
                sort: $sort
                filter: $filter
              ) {
                count
                results {
                  id
                  type
                  url
                  responseCode
                  eventName
                  apiKey
                  apiKeyName
                  createdAt
                }
              }
            }
          }
        `,
        variables: {
          fromDate: startDate,
          toDate: endDate,
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
          sort: "desc",
          filter: filter,
        },
        fetchPolicy: "network-only",
        update(data) {
          const { count, results } = _.get(data, "devCenterAPI.paginateLogs", {});
          this.pagedData = _.map(results, (result) => {
            return _.mapValues(result, (value) => {
              return !value ? "-" : value;
            });
          });
          this.totalRecordCount = count;
          this.loading = false;
        },
        error(error) {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message || "Error fetching data",
          });
          console.error(error);
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.header-title {
  line-height: 36px;
}

.date-picker {
  .bold-text {
    font-weight: bold;
  }
  .date-picker-input {
    width: 400px;
    margin-left: 15px;
    margin-bottom: 0;
  }
}
table.flatten {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table.flatten td,
table.flatten th {
  border: 1px solid $color-dark;
  text-align: left;
  padding: 4px 10px;
  background-color: transparent;
}

table.flatten tr:nth-child(even) {
  background-color: $color-light;
}

.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}
</style>
