<template>
  <div>
    <el-table
      size="mini"
      v-loading="busy"
      :class="{ 'selection-disabled': selectedRows.length >= 5 }"
      :data="paginated"
      @selection-change="(e) => (selectedRows = e)"
    >
      <el-table-column type="selection" min-width="55"> </el-table-column>
      <el-table-column prop="display_name" label="Name" min-width="180" />
      <el-table-column prop="timeAgo" label="Last Modified" min-width="180" />
      <el-table-column prop="size" label="Size" min-width="100" />
      <el-table-column label="Actions">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-delete"
            circle
            size="mini"
            plain
            type="danger"
            @click="onDeleteSnapshot(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <br />

    <el-row type="flex" justify="space-between" align="middle">
      <div>
        <el-button
          v-if="faqSnapshots.length > 10"
          :disabled="busy"
          :loading="busy"
          size="mini"
          icon="el-icon-delete"
          type="danger"
          @click="onDeleteLast10"
          >Delete Last 10 Items</el-button
        >
        <el-button
          v-if="selectedRows.length > 0"
          :disabled="busy"
          :loading="busy"
          size="mini"
          plain
          icon="el-icon-delete"
          type="danger"
          @click="onBulkDeleteSnapshot"
          >Delete Selected {{ selectedRows.length }}</el-button
        >
        <small v-if="selectedRows.length === 5" style="bdbdbd"
          >&nbsp;&nbsp;&nbsp;Only 5 selection allowed for bulk delete.</small
        >
      </div>
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        :page-size="10"
        :total="faqSnapshots.length"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import { gql } from "@apollo/client/core";
import { graph } from "@/store/api";

export default {
  props: [],
  data() {
    return {
      busy: false,
      currentPage: 1,
      selectedRows: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.busy = true;
        await this.$store.dispatch("FETCH_FAQ_SNAPSHOTS", {
          brain: this.$store.state.brain,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.busy = false;
      }
    },
    async onDeleteLast10() {
      if (!this.busy) {
        try {
          const confirmed = await this.$confirm(
            "This action will permenently delete last 10 snapshots. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          );
          if (confirmed) {
            this.busy = true;
            for (let i = this.faqSnapshots.length - 1; i >= this.faqSnapshots.length - 9; i--) {
              await this.deleteSnapshot(this.faqSnapshots[i]);
            }
            this.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `10 FAQ snapshots delete.`,
            });

            this.fetchData();
          }
        } catch (error) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Something went wrong, please try again later.`,
          });
        } finally {
          this.busy = false;
        }
      }
    },
    async deleteSnapshot(item) {
      return await graph.query({
        query: gql`
        {
          Bot {
            deleteFAQSnapshot(brain:"${item.name}", snapshot: "${item.snapshot}")
          }
        }
      `,
        fetchPolicy: "network-only",
      });
    },
    async onBulkDeleteSnapshot() {
      if (!this.busy) {
        try {
          const confirmed = await this.$confirm(
            "This action will permenently delete selected faq snapshots. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          );
          if (confirmed) {
            this.busy = true;
            for (let i = 0; i < this.selectedRows.length; i++) {
              await this.deleteSnapshot(this.selectedRows[i]);
            }
            this.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `FAQ snapshots delete.`,
            });

            this.selectedRows = [];
            this.fetchData();
          }
        } catch (error) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Something went wrong, please try again later.`,
          });
        } finally {
          this.busy = false;
        }
      }
    },
    async onDeleteSnapshot(e) {
      if (!this.busy) {
        try {
          const confirmed = await this.$confirm(
            "This action will permenently delete faq snapshot. Continue?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          );
          if (confirmed) {
            this.busy = true;
            await this.deleteSnapshot(e.row);
            this.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `FAQ snapshot delete.`,
            });
            this.fetchData();
          }
        } catch (error) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Something went wrong, please try again later.`,
          });
        } finally {
          this.busy = false;
        }
      }
    },
  },
  computed: {
    canCleanUp() {
      return this.faqSnapshots.length > 10;
    },
    paginated() {
      return this.faqSnapshots.slice((this.currentPage - 1) * 10, this.currentPage * 10);
    },
    faqSnapshots() {
      const data = _.flatten(Object.values(_.get(this.$store.state, "faqSnapshots") || {}));
      const snapshots = data.map((item) => {
        let filesize = "";
        if (item.contentLength === 0) {
          filesize = "0 Byte";
        } else {
          const k = 1024;
          const dm = 2;
          const sizes = ["Bytes", "KB", "MB", "GB"];
          const i = Math.floor(Math.log(item.contentLength) / Math.log(k));
          filesize = parseFloat((item.contentLength / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        }
        item.size = filesize;
        item.display_name = `${item.name} (${item.date})`;
        return item;
      });
      return snapshots;
    },
  },
};
</script>

<style scoped>
>>> thead .el-checkbox {
  display: none;
}
>>> .selection-disabled .el-checkbox:not(.is-checked) {
  pointer-events: none;
}
>>> .selection-disabled .el-checkbox:not(.is-checked) .el-checkbox__input {
  cursor: not-allowed;
}
</style>
