import { AccessControlState } from "./types";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { graph } from "@/store/api";
import { gql } from "@apollo/client/core";
import _ from "lodash";
import { isShowAdvanced } from "@/store/getters";

const actions: ActionTree<AccessControlState, RootState> = {
  FETCH_AUTH_USERS: ({ state, dispatch, commit, rootState }, payload = {}) => {
    const variables = {};
    const { page, size, search, order } = payload;
    Object.assign(variables, { page, size, search, order });

    // TODO: Update the existing gql endpoint to eliminate the need for checking operationName for authentication purposes
    const query = payload?.fromLivechat
      ? gql`
          query users(
            $includeKeyReply: Boolean
            $page: Int
            $size: Int
            $search: String
            $order: Int
          ) {
            accesscontrolAPI {
              users(
                includeKeyReply: $includeKeyReply
                page: $page
                size: $size
                search: $search
                order: $order
              )
            }
          }
        `
      : gql`
          query ($includeKeyReply: Boolean, $page: Int, $size: Int, $search: String, $order: Int) {
            accesscontrolAPI {
              users(
                includeKeyReply: $includeKeyReply
                page: $page
                size: $size
                search: $search
                order: $order
              )
            }
          }
        `;

    return graph
      .query({
        query,
        variables: {
          includeKeyReply: isShowAdvanced(rootState),
          ...variables,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        commit("SET_AUTH_USERS", _.get(result, "data.accesscontrolAPI.users.users"));
        commit("SET_AUTH_USER_TOTAL", _.get(result, "data.accesscontrolAPI.users.total"));
      });
  },
  SAVE_USERS: ({ state, commit }, { users }) => {
    return graph.mutate({
      mutation: gql`
        mutation ($users: [JSON]) {
          accesscontrolAPI {
            updateUsers(users: $users)
          }
        }
      `,
      variables: {
        users,
      },
    });
  },
  ADD_USER: async ({ state, commit }, user) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($user: JSON) {
            accesscontrolAPI {
              addUser(user: $user)
            }
          }
        `,
        variables: {
          user,
        },
      })
      .then((res: any) => {
        const newUser = _.get(res, "data.accesscontrolAPI.addUser");
        if (newUser) {
          commit("ADD_AUTH_USER", newUser);
          return true;
        }
      })
      .catch((err) => {});
  },
  DELETE_USER: async ({ state, commit }, { email, userId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($email: String!, $userId: String!) {
            accesscontrolAPI {
              deleteUser(email: $email, userId: $userId)
            }
          }
        `,
        variables: {
          email,
          userId,
        },
      })
      .then((res: any) => {
        const userDeleted = _.get(res, "data.accesscontrolAPI.deleteUser");
        if (userDeleted) {
          commit("DELETE_AUTH_USER", email);
          return true;
        }

        return false;
      })
      .catch((err) => {});
  },
};

export default actions;
