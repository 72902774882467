<template>
  <div class="published-list">
    <EditIntent
      v-if="openEdit"
      :handleEditModel="handleOpenModel"
      :listTrending="currentlyData"
      :intentEdit="intentEdit"
    />

    <div class="published-list-currently">
      <div class="published-list-currently-list">
        <p
          style="padding: 16px 20px; font-size: 14px; margin: 0"
          v-if="currentlyPublished.length == 0"
        >
          You don't have any currently published topic
        </p>
        <table v-else>
          <thead>
            <tr>
              <th>
                <p style="margin: 0; color: black; text-align: start; padding-left: 30px">
                  Currently published
                </p>
              </th>
              <th></th>
              <th><p style="margin: 0; color: gray">Trending score</p></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              ref="currentlyTable"
              v-for="currently in currentlyPublished"
              :key="`currently-${currently.id}`"
              v-dragging="{
                item: currently,
                list: currentlyPublished,
                group: 'currentlyPublished',
              }"
            >
              <td>
                <div style="display: flex; align-items: center; height: 60px">
                  <div style="text-align: right">
                    <i class="el-icon-s-unfold" style="font-size: 25px"></i>
                  </div>
                  <div
                    v-if="getIntentName(currently.intentId).intentName"
                    style="margin-left: 5px; text-align: start; display: flex; align-items: center"
                  >
                    <div>
                      <b>
                        {{ getIntentName(currently.intentId).intentName }}
                        <el-tag style="margin-left: 5px" type="success">Intent</el-tag>
                      </b>
                      <p style="margin: 0">{{ getTextFromIntentId(currently.intentId) }}</p>
                    </div>
                  </div>
                  <div
                    v-else
                    style="margin-left: 5px; text-align: start; display: flex; align-items: center"
                  >
                    <div v-if="existPublishedExample(currently.examples)">
                      <b>
                        {{ existPublishedExample(currently.examples) }}
                        <el-tag style="margin-left: 5px" type="success">Content Node</el-tag>
                      </b>
                      <p style="margin: 0">{{ getTextFromIntentId(currently.intentId) }}</p>
                    </div>
                    <div v-else>
                      {{ getTextFromIntentId(currently.intentId) }}
                      <el-tag style="margin-left: 5px" type="success">Content Node</el-tag>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <i
                    class="el-icon-edit"
                    style="font-size: 25px"
                    @click="handleEditModel(currently)"
                  ></i>
                </div>
              </td>
              <td>
                <p style="text-align: center; margin: 0">{{ currently.count }}</p>
              </td>
              <td>
                <i
                  class="el-icon-delete"
                  style="font-size: 25px"
                  @click="removeCurrentlyPublished(currently.id)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="published-list-previous" :style="heigthPreviousList()">
      <div class="published-list-previous-list">
        <p v-if="previousPublished.length == 0">You don't have any previously published topic</p>
        <table v-else>
          <thead>
            <tr>
              <th>
                <p style="margin: 0; color: black; text-align: start; padding-left: 10px">
                  Previous published
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(previous, i) in previousPublished" :key="`previous-${i}`">
              <td>
                <div
                  v-if="getIntentName(previous.intentId).intentName"
                  style="
                    margin-left: 10px;
                    text-align: start;
                    display: flex;
                    align-items: center;
                    height: 60px;
                  "
                >
                  <div>
                    <b> {{ getIntentName(previous.intentId).intentName }}</b>
                    <p style="margin: 0">{{ getTextFromIntentId(previous.intentId) }}</p>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    margin-left: 10px;
                    text-align: start;
                    display: flex;
                    align-items: center;
                    height: 60px;
                  "
                >
                  <div v-if="existPublishedExample(previous.examples)">
                    <b> {{ existPublishedExample(previous.examples) }}</b>
                    <p style="margin: 0">{{ getTextFromIntentId(previous.intentId) }}</p>
                  </div>
                  <div v-else>
                    <p style="margin: 0">{{ getTextFromIntentId(previous.intentId) }}</p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { gql } from "@apollo/client/core";
import {
  getPublishedExample,
  getTextButton,
  getIntentName,
} from "@/helperMethods/trendingTopics/methods";
import { mixin } from "@/helperMethods/trendingTopics/mixins";
import EditIntent from "./EditIntent/Index.vue";

export default {
  components: { EditIntent },
  props: ["setLoading", "handleTimeSort", "heightTrending"],
  mixins: [mixin],
  data() {
    return {
      currentlyData: [],
      openEdit: false,
      changedOrderCurrently: [],
      isSaved: false,
      isLoading: false,
      cover: 0,
      intentEdit: {},
    };
  },
  updated() {
    this.cover = document.getElementsByClassName("published-list-currently")[0].offsetHeight;
  },
  mounted() {
    this.cover = document.getElementsByClassName("published-list-currently")[0].offsetHeight;
  },
  computed: {
    currentlyPublished() {
      return this.$store.state.currentlyTopics;
    },
    previousPublished() {
      return this.$store.state.previousTopics;
    },
  },
  methods: {
    getIntentName(intentId) {
      return getIntentName(intentId);
    },
    heigthPreviousList() {
      return {
        height: `calc(100% - ${this.cover}px)`,
      };
    },
    handleEditModel(data) {
      this.currentlyData = [data];
      this.intentEdit = data;
      this.handleOpenModel();
    },
    handleOpenModel() {
      this.openEdit = !this.openEdit;
    },
    getTextFromIntentId(data) {
      return getTextButton(data);
    },
    existPublishedExample(examples) {
      return getPublishedExample(examples);
    },
    async removeCurrentlyPublished(id) {
      const removedCurrently = [...this.currentlyPublished].filter(
        (currently) => currently.id !== id
      );
      this.$store.commit("SET_CURRENTLY_TOPICS", removedCurrently);
      this.$emit("select-intent", id);
    },
    async updateOrderCurrently() {
      this.isLoading = true;
      const dataToUpdate = [...this.currentlyPublished].map((currently, index) => ({
        id: currently.id,
        order: index + 1,
      }));
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($data: [JSON]) {
              updateOrderCurrentlyTopic(data: $data)
            }
          `,
          variables: {
            data: dataToUpdate,
          },
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "error",
            message: "Failed to update order topic.",
          });
          return false;
        });
      if (isSaved) {
        this.$message({
          type: "success",
          message: "Order Updated",
          showClose: true,
        });
        this.isSaved = true;
        this.isLoading = false;
      }
    },
  },
  watch: {
    currentlyPublished: function () {
      this.isSaved = false;
      const orderCurrently = [...this.currentlyPublished].map((data, index) => ({
        id: data.id,
        order: index + 1,
      }));
      this.$store.commit("SET_ORDER_CURRENTLY", orderCurrently);
      this.handleTimeSort();
      this.cover = document.getElementsByClassName("published-list-currently")[0].offsetHeight;
    },
  },
  created() {
    this.isSaved = false;
    const orderCurrently = [...this.currentlyPublished].map((data, index) => ({
      id: data.id,
      order: index + 1,
    }));
    this.$store.commit("SET_ORDER_CURRENTLY", orderCurrently);
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.el-table thead tr {
  > th:first-child {
    color: black;
  }
  > th:not(:first-child) {
    text-align: center;
  }
}

.published-list {
  box-shadow: -4px -3px 3px 2px rgba(0, 0, 0, 0.17);
  height: 100%;
  h3 {
    color: gray;
  }
  &-currently {
    &-list {
      position: relative;
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }
  }
  &-previous {
    border-top: 3px solid black;
    &-list {
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      padding: 0px 20px;
      height: 100%;
      overflow-y: scroll;
    }
  }
}
table {
  width: 100%;
  color: $color-dark;
  border-spacing: 0;
  padding: 0px 5px;
}
table th {
  padding: 6px 0;
}
table thead tr {
  height: 45px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #757a834f;
  }
}
table tbody tr {
  height: 45px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #757a834f;
  }
  &:hover {
    background: #676b721f;
    cursor: pointer;
  }
}
</style>
