<template>
  <el-header v-if="isNotify" class="secondary-header notify-file-purge-banner">
    {{ label }}
  </el-header>
</template>

<script>
import { gql } from "@apollo/client/core";
import _ from "lodash";

import { printDuration } from "@/helperMethods/livechat/util";

export default {
  name: "PurgeBanner",
  data() {
    return {
      isNotify: false,
      label: "",
    };
  },
  methods: {
    notifyCountdownTimer(type, endStr) {
      const nowTimestamp = new Date().getTime();
      const endTimestamp = new Date(endStr).getTime();
      if (type === "notify" && nowTimestamp < endTimestamp - 2000) {
        setTimeout(() => {
          this.isNotify = true;
          this.label = `Images will be purged in about ${printDuration(
            new Date().toISOString(),
            endStr,
            true
          )} from now.`;
          this.notifyCountdownTimer(type, endStr);
        }, 1000);
      }
      if (type === "start") {
        this.isNotify = true;
        this.label = "Image Purging is in progress...";
      }
      if (type === "finish") {
        this.label = "Image Purging has been done!";
        setTimeout(() => {
          this.isNotify = false;
          this.label = "";
        }, 1000);
      }
    },
    refetchNotify() {
      if (_.get(this, "$store.state.modules.filePurge.enabled", false)) {
        this.$store.dispatch("FETCH_FILE_PURGE_STATUS").then(({ data }) => {
          const type = _.get(data, "livechatAPI.getUpdatedNotifyFilePurge.type");
          const purgeTime = _.get(data, "livechatAPI.getUpdatedNotifyFilePurge.purgeTime");
          if (type && purgeTime) {
            this.notifyCountdownTimer(type, purgeTime);
          }
        });
      }
    },
  },
  mounted() {
    this.refetchNotify();
  },
  apollo: {
    $subscribe: {
      livechatNotifyFilePurging: {
        query: gql`
          subscription {
            livechatNotifyFilePurging
          }
        `,
        result({ data }) {
          const type = _.get(data, "livechatNotifyFilePurging.type");
          const purgeTime = _.get(data, "livechatNotifyFilePurging.purgeTime");
          this.notifyCountdownTimer(type, purgeTime);
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors.scss";

.notify-file-purge-banner {
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  vertical-align: center;
  height: auto !important;
  background-color: $color-warning;
  color: #fff;
}
</style>
