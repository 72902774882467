import { SYSTEM_MESSAGE } from "@/lib/viewConstants";
import { graph, rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { InteractionType, LivechatState, MessageStatus } from "./types";
import * as Sentry from "@sentry/browser";

import { gql } from "@apollo/client/core";
import * as _ from "lodash";
import axios from "axios";
import moment from "moment";
import LocalStorageManager from "@/localStorageManager";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import cuid from "cuid";
import { handleMessageSendingError, isConnectionAvailable } from "./utils";
import { splitAbandonedResolvedChats } from "@/helperMethods/livechat/splitAbandonedResolvedChats";

const FETCH_RESOLVED_CHATS_QUERY = require("@/graphql/LIVECHATS_GET_RESOLVED.gql");
const FETCH_QUEUE_CHATS_QUERY = require("@/graphql/LIVECHATS_GET_QUEUE.gql");

const TAGS_DELETE = require("@/graphql/TAGS_DELETE.gql");
const TAGS_CREATE = require("@/graphql/TAGS_CREATE.gql");
const DEPARTMENTS_GET = require("@/graphql/DepartmentsGet.graphql");

const actions: ActionTree<LivechatState, RootState> = {
  RESET_UNREAD_MESSAGE: ({ state, commit }, chatId) => {
    LocalStorageManager.setUnreadMessageListUsingChatId({
      chatId,
      unreadMessage: 0,
    });
    const targetChat = state.queueChatObject[chatId];
    targetChat.unreadMessage = 0;
    commit("UPDATE_QUEUE_CHAT", { updatedLivechat: targetChat });
  },
  HANDLE_NEW_MESSAGE: ({ state, commit }, { newMessage }) => {
    const user_id = newMessage.user_id;
    const selectChatId = state.selectedChatId;
    const PartitionKey = newMessage.PartitionKey;
    const newInteraction = newMessage.message;
    const targetChat = _.find(state.queueChatObject, (chat) => {
      return chat.user_id === user_id && chat.PartitionKey === PartitionKey && !chat.isRemoved;
    });

    if (!targetChat) {
      return {};
    }

    let interactions: InteractionType[] = _.cloneDeep(_.get(targetChat, "interactions", []));
    const existMessageIdx = interactions.findIndex(
      (interaction) => interaction.RowKey === newInteraction.RowKey
    );

    // handle duplicated messages:
    //  - insert for new message
    //  - or update existed message
    if (existMessageIdx === -1) {
      interactions.push(newInteraction);
      interactions = _.orderBy(
        interactions,
        [
          (interaction) => {
            return moment(interaction.date_created).valueOf();
          },
        ],
        ["desc"]
      );

      const simplifiedMessage = simplifyMessage(newMessage.message) || {
        text: "",
      };

      const userDisconnected =
        simplifiedMessage.text &&
        simplifiedMessage.text.toLowerCase().includes(SYSTEM_MESSAGE.disconnected);

      if (userDisconnected) {
        commit("UPDATE_TYPING_INDICATOR_FOR_CHAT", {
          user_id,
          isTyping: false,
        });
      }

      const isUserMessage = _.get(newMessage, "message.type") !== "reply";
      const targetAndSelectedChatNotSame = selectChatId !== targetChat.RowKey;

      if (isUserMessage && targetAndSelectedChatNotSame) {
        targetChat.unreadMessage++;
      }

      const updatedLivechat = { ...targetChat, interactions };
      commit("UPDATE_QUEUE_CHAT", { updatedLivechat });

      // update local store with unread message badge
      // save unreadmessages list in local storage.
      LocalStorageManager.setUnreadMessageListUsingChatId({
        chatId: targetChat.RowKey,
        unreadMessage: targetChat.unreadMessage,
      });
    } else {
      const existingInteraction = interactions[existMessageIdx];
      if (!newInteraction?.data?.context) {
        newInteraction.data.context = _.get(existingInteraction, "data.context");
      }
      newInteraction.data.contextPayload = _.get(existingInteraction, "data.contextPayload", null);
      interactions[existMessageIdx] = newInteraction;
      const updatedLivechat = { ...targetChat, interactions };
      commit("UPDATE_QUEUE_CHAT", { updatedLivechat });
    }

    return targetChat;
  },

  SET_TYPING_INDICATOR_FOR_CHAT({ state, commit }, { user_id, isTyping }) {
    commit("UPDATE_TYPING_INDICATOR_FOR_CHAT", {
      user_id,
      isTyping,
    });
  },
  FETCH_AGENTS: ({ dispatch }) => {
    return graph
      .query({
        query: gql`
          query {
            livechatAPI {
              agents
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((agents) => {
        const payload = _.get(agents, "data.livechatAPI.agents", {});
        dispatch("SET_CONNECTED_AGENTS", payload);
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  },
  SET_CONNECTED_AGENTS: ({ commit, rootState }, payload) => {
    const agents = _.values(payload);
    commit("SET_CONNECTED_AGENTS", agents);
  },
  CREATE_TAG: ({ state }, { newTag, chat }) => {
    return graph
      .mutate({
        mutation: TAGS_CREATE,
        variables: {
          newTag,
          chat,
        },
      })
      .catch((error) => {
        throw error;
      });
  },
  DELETE_TAG: ({ state }, { tagToDelete, chat }) => {
    return graph
      .mutate({
        mutation: TAGS_DELETE,
        variables: {
          chat,
          tagToDelete,
        },
      })
      .catch((error) => {
        throw error;
      });
  },
  // FIXME: not required for checking cors allow origin
  // GET_STORAGE_HOST(store) {
  //   return rest("get", "webchat_get_storage_host", {});
  // },
  // VERIFY_CORS_SAS_URL(store, { url }) {
  //   const sasUrlsNotFound = !url;

  //   if (sasUrlsNotFound) {
  //     return Promise.reject({ response: { status: 403, message: "SAS URL is not found" } })
  //   }

  //   const data = {
  //     sasUrl: url,
  //     origin,
  //     method: "PUT"
  //   }

  //   return rest("post", "webchat_verify_cors", data);
  // },
  REQUEST_SAS(store, { fileNames }): Promise<string[]> {
    // TODO get SAS URL
    // Sanitize file names
    const sanitizedFilenames = fileNames.map((filename: string) => {
      const ext = _.last(filename.split("."));
      const name = filename.substr(0, filename.lastIndexOf("."));
      const sanitizedName = name.replace(/\W+/g, "-");
      return [sanitizedName, ext].join(".");
    });

    return rest("post", "webchat_request_upload", {
      filenames: sanitizedFilenames,
    }).catch((err) => {
      throw err;
    });
  },

  DELETE_FILES(store, { fileNames }): Promise<string[]> {
    return rest("post", "webchat_delete_file", {
      filenames: fileNames,
    }).catch((err) => {
      throw err;
    });
  },

  UPLOAD_FILE(store, { sasUrls, formData, files, isIEOrEdge, rowKey }) {
    // IE will use an endpoint to upload due to script 7002 CORS issue
    const sasUrlsNotRequired = sasUrls.length === 0;
    return new Promise((resolve, reject) => {
      if (isIEOrEdge && typeof sasUrls === "object") {
        formData.append("sasUrls", JSON.stringify(sasUrls));
        rest("post", "webchat_file_upload", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else if (sasUrlsNotRequired) {
        // if SAS URLs are not required
        const form = new FormData();
        Array.from(files).forEach((file: any) => {
          form.append("file", file, file.name);
        });

        rest("post", "webchat_file_upload", form)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });

        // FIXME: @Dhoni after testing on ceph success, it will be deprecated.
        // const promises = Array.from(files).map((file: any) => {
        //   const form = new FormData();
        //   form.append("file", file, file.name);
        //   return rest("post", "webchat_file_upload", form);
        // });

        // Promise.all(promises)
        //   .then((response) => {
        //     resolve(response);
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
      } else {
        const promises = sasUrls.map((url: string, index: string) => {
          if (files[index] instanceof File) {
            const file = files[index];
            return axios
              .put(url, files[index], {
                headers: {
                  "content-type": file.type,
                  "x-ms-blob-type": "BlockBlob",
                  "x-ms-meta-owner": rowKey,
                },
              })
              .catch((err) => {
                throw err;
              });
          }
        });
        Promise.all(promises)
          .then(() => {
            resolve([]);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  CHECK_RESOLVED_LIVECHAT: (store, { chat }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON) {
            livechatAPI {
              checkResolvedLivechat(chat: $chat)
            }
          }
        `,
        variables: {
          chat,
        },
      })
      .then((response) => {
        const result = _.get(response, "data.livechatAPI.checkResolvedLivechat");

        return result;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw err;
      });
  },
  CHECK_LIVECHAT_AVAILABILITY: (store, { chat }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON) {
            livechatAPI {
              getLivechatAvalibility(chat: $chat)
            }
          }
        `,
        variables: {
          chat,
        },
      })
      .then((response) => {
        const result = _.get(response, "data.livechatAPI.getLivechatAvalibility");

        return result;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw err;
      });
  },
  JOIN_LIVECHAT: (store, { chat, agentId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON, $agentId: String) {
            livechatAPI {
              joinLiveChat(chat: $chat, agentId: $agentId)
            }
          }
        `,
        variables: {
          chat,
          agentId,
        },
      })
      .then((response) => {
        const responseData = _.get(response, "data.livechatAPI.joinLiveChat");
        if (responseData.success) {
          // state.livechat_data.agents = result.livechat_data.agents;
          store.commit("UPDATE_LIVECHAT", responseData.chat);
        } else {
          store.commit("SET_SELECTED_CHAT_ID", null);
        }
        return responseData;
      })
      .catch((err) => {
        Sentry.captureMessage("DASHBOARD:ACTION:JOIN_LIVECHAT encountered error");
      });
  },
  LEAVE_LIVECHAT: ({ commit }, { chat, agentId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON, $agentId: String) {
            livechatAPI {
              leaveLiveChat(chat: $chat, agentId: $agentId)
            }
          }
        `,
        variables: {
          chat,
          agentId,
        },
      })
      .then((response) => {
        const result = _.get(response, "data.livechatAPI.leaveLiveChat");
        if (result) {
          // state.livechat_data.agents = result.livechat_data.agents;
          commit("UPDATE_LIVECHAT", result);
          commit("SET_SELECTED_CHAT_ID", null);
          return chat;
        }
      })
      .catch((err) => {
        Sentry.captureMessage("DASHBOARD:ACTION:JOIN_LIVECHAT encountered error");
      });
  },
  FETCH_AGENT_DEPARTMENTS: ({ state, commit }, variables) => {
    return graph
      .query({
        query: DEPARTMENTS_GET,
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        const departments = _.get(result, "data.agentSettingsAPI.departments", []);
        return departments;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_ALL_RESOLVED_CHATS: ({ state, commit }, variables) => {
    return graph
      .query({
        query: FETCH_RESOLVED_CHATS_QUERY,
        variables,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const cloneFetchInteractions = _.cloneDeep(response);
        const chats = _.get(cloneFetchInteractions, "data.livechatAPI.resolvedSessions");
        const resolvedChats = _.filter(chats, (chat) => chat.agents && chat.agents.length > 0);

        return {
          chats: _.sortBy(_.compact(resolvedChats), (interaction: any) => {
            return -moment(interaction.Timestamp).unix();
          }),
          unfilteredChatsLength: chats.length,
        };
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_ALL_ABANDONED_CHATS: ({ state, commit }, variables) => {
    return graph
      .query({
        query: FETCH_RESOLVED_CHATS_QUERY,
        variables,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const cloneFetchInteractions = _.cloneDeep(response);
        const chats = _.get(cloneFetchInteractions, "data.livechatAPI.resolvedSessions");
        const abandonedChats = _.filter(chats, (chat) => !chat.agents || chat.agents.length <= 0);

        return {
          chats: _.sortBy(_.compact(abandonedChats), (interaction: any) => {
            return -moment(interaction.Timestamp).unix();
          }),
          unfilteredChatsLength: chats.length,
        };
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_ALL_QUEUE_CHATS: async (
    { state, commit },
    { transferRedactedId, transferRedactedIdInteractions, limit } = { limit: 20 }
  ) => {
    const fetchQueueChatTask = graph.query({
      query: FETCH_QUEUE_CHATS_QUERY,
      fetchPolicy: "network-only",
      variables: {
        limit,
        waitingForAssignment: true,
      },
    });

    // @todo: separate action & state for this ongoing chat
    const fetchOngoingChatTask = graph.query({
      query: FETCH_QUEUE_CHATS_QUERY,
      fetchPolicy: "network-only",
      variables: {
        limit,
        waitingForAssignment: false,
        involvedMe: true,
      },
    });

    const [responseForQueueChats, responseForOnGoingChats] = await Promise.all([
      fetchQueueChatTask,
      fetchOngoingChatTask,
    ]);

    const { userSessions: incomingUnresolvedSessions, count } = _.get(
      responseForQueueChats,
      "data.livechatAPI.unresolvedSessions",
      { userSessions: [], count: 0 }
    );

    const { userSessions: onGoingSessions } = _.get(
      responseForOnGoingChats,
      "data.livechatAPI.unresolvedSessions",
      { onGoingSessions: [] }
    );

    const clonedSession = _.cloneDeep([...incomingUnresolvedSessions, ...onGoingSessions]);

    const selectedChatId = _.get(state, "selectedChatId");
    const selectedChat = state.queueChatObject[selectedChatId];

    const updatedSessions = _.chain(clonedSession)
      .map((chat, _index) => {
        if (selectedChat && chat.RowKey === selectedChatId) {
          chat.interactions = [...selectedChat.interactions, ...chat.interactions];
        }

        if (
          transferRedactedIdInteractions &&
          transferRedactedIdInteractions.length &&
          transferRedactedId !== null &&
          transferRedactedId === chat.RowKey
        ) {
          chat.interactions = transferRedactedIdInteractions;
        }
        const unreadMessageList = LocalStorageManager.getUnreadMessageList();

        const targetChat = _.find(unreadMessageList, {
          chatId: chat.RowKey,
        });
        let unreadMessage = 0;
        if (targetChat) {
          unreadMessage = targetChat.unreadMessage;
        }

        // FIXME: additional values for chat
        // TODO: move them into local vuex store
        chat.reply = "";
        chat.typingIndicatorStatus = false;
        chat.unreadMessage = unreadMessage;
        return chat;
      })
      .sortBy(
        // TODO: REMOVE
        // custom sort for parkway queue
        (chat) => {
          return !chat.tags.join("").includes("appointment");
        }
      )
      .value();

    const updatedSessionsObject = _.keyBy(updatedSessions, (chat) => {
      return chat.RowKey;
    });
    commit("SET_QUEUE_CHAT_OBJECT", {
      queueChatObject: updatedSessionsObject,
      count,
    });

    commit("SET_FETCH_QUEUE_CHAT_LOADING", false);
  },
  CHANGE_APPEAR_ONLINE: (
    { commit },
    { newStatus, previousStatus }: { newStatus: string; previousStatus: string }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($newStatus: String, $previousStatus: String) {
            livechatAPI {
              updateAgentStatus(newStatus: $newStatus, previousStatus: $previousStatus)
            }
          }
        `,
        variables: {
          newStatus,
          previousStatus,
        },
      })
      .then((response) => {
        const updateResponse = _.get(response, "data.livechatAPI.updateAgentStatus");
        commit("SET_AGENT_STATUS", updateResponse.newStatus);
        return updateResponse;
      })
      .catch((error) => {
        commit("SET_AGENT_STATUS", "OFFLINE");
        const message =
          error.message.replace("GraphQL error: ", "") || "Error changing appear status";
        throw new Error(message);
      });
  },
  LIVECHAT_INVITE_AGENT: async (
    { state, commit },
    { chat, invitee, memoComment, transferRedacted }
  ) => {
    return await graph
      .mutate({
        mutation: gql`
          mutation (
            $chat: JSON
            $invitee: String
            $memoComment: String
            $transferRedacted: Boolean
          ) {
            livechatAPI {
              inviteAgent(
                chat: $chat
                invitee: $invitee
                memoComment: $memoComment
                transferRedacted: $transferRedacted
              )
            }
          }
        `,
        variables: {
          chat,
          invitee,
          memoComment,
          transferRedacted,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },
  LIVECHAT_ACCEPT_INVITATION: (store, { chat, transferRedacted }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON, $transferRedacted: Boolean) {
            livechatAPI {
              acceptInvitation(chat: $chat, transferRedacted: $transferRedacted)
            }
          }
        `,
        variables: {
          chat,
          transferRedacted,
        },
      })
      .then((response) => {
        const acceptedInvitation = _.get(response, "data.livechatAPI.acceptInvitation");
        return acceptedInvitation;
      })
      .catch((err) => {
        throw err;
      });
  },

  SEND_TO_LIVECHAT_QUEUE: (store, { user, agentEmail }) => {
    const newAgentsList = user.agents.includes(agentEmail)
      ? _.uniq(user.agents)
      : _.concat(user.agents, [agentEmail]);
    const userLivechatData = Object.assign({}, user, {
      agents: newAgentsList,
    });

    return graph
      .mutate({
        mutation: gql`
          mutation ($livechatData: JSON, $senderId: String, $recipientId: String) {
            livechatAPI {
              sendToQueue(
                livechatData: $livechatData
                senderId: $senderId
                recipientId: $recipientId
              )
            }
          }
        `,
        variables: {
          livechatData: userLivechatData,
          senderId: userLivechatData.RowKey,
          recipientId: userLivechatData.PartitionKey,
        },
      })
      .then((res) => {
        const result = _.get(res, "data.livechatAPI.sendToQueue");
        return result;
      })
      .catch((err) => {
        throw err;
      });
  },
  EMAIL_CHAT_TRANSCRIPT: ({ state, commit }, { userId, partitionKey, channel, agentEmail }) => {
    return graph
      .query({
        query: gql`
          query ($userId: String, $sessionPartitionKey: String, $agentEmail: String) {
            livechatAPI {
              emailLivechatTranscript(
                userId: $userId
                sessionPartitionKey: $sessionPartitionKey
                agentEmail: $agentEmail
              )
            }
          }
        `,
        variables: {
          userId,
          sessionPartitionKey: partitionKey,
          agentEmail,
        },
      })
      .then((response) => {
        const isForwarded = _.get(response, "data.livechatAPI.emailLivechatTranscript");
        return isForwarded;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_SESSION_BY_USER_ID_AND_PARTITION_KEY: (
    { state, commit },
    { userId, sessionPartitionKey, isResolved }
  ) => {
    return graph
      .query({
        query: gql`
          query ($userId: String!, $isResolved: Boolean!, $sessionPartitionKey: String!) {
            livechatAPI {
              getSessionByUserIdAndPartitionKey(
                userId: $userId
                sessionPartitionKey: $sessionPartitionKey
                isResolved: $isResolved
              )
            }
          }
        `,
        variables: {
          userId,
          sessionPartitionKey: sessionPartitionKey,
          isResolved,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const chat = _.get(result, "data.livechatAPI.getSessionByUserIdAndPartitionKey", []);
        return chat;
      })
      .catch((error) => {
        throw error;
      });
  },
  FETCH_MORE_MONITOR_LIVECHAT_INTERACTION: ({ state, commit, dispatch }, fetchMorePayload) => {
    const { userId, partitionKey, limit, offset } = fetchMorePayload;
    return graph
      .query({
        query: gql`
          query ($userId: ID!, $partitionKey: String!, $limit: Int!, $offset: Int!) {
            livechatAPI {
              fetchMoreMonitorChatInteractions(
                userId: $userId
                partitionKey: $partitionKey
                limit: $limit
                offset: $offset
              )
            }
          }
        `,
        variables: { userId, partitionKey, limit, offset },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const interactions = _.get(result, "data.livechatAPI.fetchMoreMonitorChatInteractions");
        return interactions;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_MORE_LIVECHAT_INTERACTION: ({ state }, fetchMorePayload) => {
    const { userId, partitionKey, limit, offset } = fetchMorePayload;
    return graph
      .query({
        query: gql`
          query ($sessionRowKey: String!, $partitionKey: String!, $limit: Int!, $offset: Int!) {
            livechatAPI {
              fetchLivechatSessionInteractions(
                sessionRowKey: $sessionRowKey
                partitionKey: $partitionKey
                limit: $limit
                offset: $offset
              )
            }
          }
        `,
        variables: { sessionRowKey: userId, partitionKey, limit, offset },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const interactions = _.get(response, "data.livechatAPI.fetchLivechatSessionInteractions");
        return interactions;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_INTERACTIONS_SUMMARY: ({ state, commit }, { dates }: { dates: string[] }) => {
    return graph
      .query({
        query: gql`
          query ($dates: [String]) {
            interactionsSummary(dates: $dates, minimumCount: 3)
          }
        `,
        variables: {
          dates,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return _.get(response, "data.interactionsSummary");
      })
      .catch((err) => {
        throw err;
      });
  },

  RESOLVE_LIVECHAT: ({ commit }, { sessionPartitionKey, userId, sessionRowKey }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($userId: String, $sessionPartitionKey: String, $sessionRowKey: String) {
            livechatAPI {
              endSession(
                userId: $userId
                sessionPartitionKey: $sessionPartitionKey
                sessionRowKey: $sessionRowKey
                endedBy: "agent"
              )
            }
          }
        `,
        variables: {
          sessionPartitionKey,
          userId,
          sessionRowKey,
        },
      })
      .then((result) => {
        const isResolved = _.get(result, "data.livechatAPI.endSession.isResolved");
        return isResolved;
      })
      .catch((error) => {
        throw error;
      });
  },
  END_SESSION_FOR_USER: ({ state }, { chat, sendPostChatSurvey }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation (
            $user: JSON
            $sessionRowKey: String
            $sendPostChatSurvey: Boolean
            $endedBy: String
            $sendResponse: Boolean
          ) {
            livechatAPI {
              endSessionForUser(
                user: $user
                sessionRowKey: $sessionRowKey
                sendPostChatSurvey: $sendPostChatSurvey
                endedBy: $endedBy
                sendResponse: $sendResponse
              )
            }
          }
        `,
        variables: {
          user: { userId: chat.user_id, PartitionKey: chat.PartitionKey },
          sessionRowKey: chat.RowKey,
          sendPostChatSurvey,
          endedBy: "agent",
          sendResponse: true,
        },
      })
      .catch((error) => {
        const sentryMessage = `submit form data error: ${error}, chat: ${chat}, sendPostChatSurvey: ${sendPostChatSurvey}`;
        Sentry.captureException(sentryMessage);
        throw error;
      });
  },
  END_SESSION_FOR_AGENT: ({ commit }, { sessionPartitionKey, userId, sessionRowKey }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation (
            $userId: String
            $sessionPartitionKey: String
            $sessionRowKey: String
            $endedBy: String
          ) {
            livechatAPI {
              endSessionForAgent(
                userId: $userId
                sessionPartitionKey: $sessionPartitionKey
                sessionRowKey: $sessionRowKey
                endedBy: $endedBy
              )
            }
          }
        `,
        variables: {
          sessionPartitionKey,
          userId,
          sessionRowKey,
          endedBy: "agent",
        },
      })
      .then((result) => {
        const isResolved = _.get(result, "data.livechatAPI.endSessionForAgent.isResolved");
        return isResolved;
      })
      .catch((error) => {
        throw error;
      });
  },
  SET_CHAT_DEPARTMENT: ({ state, commit }, { chat, toDepartment, transferRedacted }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON, $toDepartment: String, $transferRedacted: Boolean) {
            livechatAPI {
              setLivechatDepartment(
                chat: $chat
                toDepartment: $toDepartment
                transferRedacted: $transferRedacted
              )
            }
          }
        `,
        variables: {
          chat,
          toDepartment,
          transferRedacted,
        },
      })
      .then((result) => {
        commit("REMOVE_QUEUE_CHAT", { resolvedChatId: chat.RowKey });
        commit("SET_SELECTED_CHAT_ID", null);
        return result;
      })
      .catch((err) => {
        Sentry.captureMessage("DASHBOARD:ACTION:SET_CHAT_DEPARTMENT encountered error");
        return err;
      });
  },
  TOGGLE_WEBCHAT_TYPING_INDICATOR: ({ state }, { chat, isTyping }) => {
    const RowKey = _.get(chat, "user_id", null);
    const PartitionKey = _.get(chat, "PartitionKey", null);
    if (!RowKey || !PartitionKey) {
      Sentry.captureMessage(
        "DASHBOARD:ACTION:TOGGLE_WEBCHAT_TYPING_INDICATOR: chat parameter is undefined"
      );
      return;
    }
    return graph
      .mutate({
        mutation: gql`
          mutation ($RowKey: String, $PartitionKey: String, $isTyping: Boolean) {
            livechatAPI {
              updateClientTypingIndicator(
                RowKey: $RowKey
                PartitionKey: $PartitionKey
                isTyping: $isTyping
              )
            }
          }
        `,
        variables: {
          RowKey,
          PartitionKey,
          isTyping,
        },
      })
      .catch((err) => {
        Sentry.captureMessage("DASHBOARD:ACTION:TOGGLE_WEBCHAT_TYPING_INDICATOR encountered error");
      });
  },
  SEND_MESSAGE: async ({ getters, state, commit }, { reply }) => {
    const newMessage = {
      ...reply,
      id: cuid(),
    };
    const user_id = reply.user_id;
    const PartitionKey = reply.page_id;
    const selectedChatInteractions = _.get(getters, "selectedChat.interactions", []);

    const contextPayload = selectedChatInteractions.find(
      (item) => item.RowKey === reply.contextRowKey
    );

    const newInteraction = {
      PartitionKey,
      RowKey: newMessage.id,
      user_id,
      Timestamp: moment().toISOString(),
      data: {
        content: [{ text: reply.text }],
        context: {
          id: reply.contextRowKey,
        },
        contextPayload,
      },
      date_created: moment().toISOString(),
      last_modified: moment().toISOString(),
      source: "webchat",
      type: "agent",
      session: "",
      status: MessageStatus.SENDING,
    };
    const targetChat = _.find(state.queueChatObject, (chat) => {
      return chat.user_id === user_id && chat.PartitionKey === PartitionKey && !chat.isRemoved;
    });
    if (targetChat) {
      let interactions: InteractionType[] = _.cloneDeep(_.get(targetChat, "interactions", []));
      interactions.push(newInteraction);
      interactions = _.orderBy(
        interactions,
        [
          (interaction) => {
            return moment(interaction.date_created).valueOf();
          },
        ],
        ["desc"]
      );
      const updatedLivechat = Object.assign({}, targetChat, { interactions });
      commit("UPDATE_QUEUE_CHAT", { updatedLivechat });
      commit("SET_REPLY");

      try {
        if (!isConnectionAvailable(state, navigator.onLine)) {
          throw Error("Socket is not connected!");
        }

        return await graph.mutate({
          mutation: gql`
            mutation ($newMessage: JSON) {
              livechatAPI {
                isAdded: reply(message: $newMessage)
              }
            }
          `,
          variables: {
            newMessage,
          },
        });
      } catch (error) {
        const sentryMessage = `Sending message error : ${JSON.stringify(
          error
        )}, message: ${JSON.stringify(newMessage)}`;
        Sentry.captureException(new Error(sentryMessage));

        handleMessageSendingError(state, newInteraction.RowKey, updatedLivechat, commit);
        throw error;
      }
    } else {
      const errorMessage = "Unable to find target chat.";
      const errorData = {
        newInteraction,
        queueStateObject: state.queueChatObject,
      };

      console.error(`[SEND_MESSAGE] ${errorMessage}`, errorData);
      const sentryMessage = `[SEND_MESSAGE] ${errorMessage}, data: ${JSON.stringify(errorData)}`;
      Sentry.captureException(new Error(sentryMessage));

      throw new Error(`${errorMessage} Please refresh the page.`);
    }
  },

  RESEND_MESSAGE: async ({ state, commit }, { messageId }) => {
    const selectedChatId = _.get(state, "selectedChatId", "");
    const selectedChat = _.find(state.queueChatObject, (chat) => {
      return chat.RowKey === selectedChatId;
    });
    const interactions = _.get(selectedChat, "interactions", []);
    const resendingInteraction = _.find(interactions, (interaction) => {
      return interaction.RowKey === messageId;
    });

    if (selectedChat && resendingInteraction) {
      const { user_id, PartitionKey } = selectedChat;
      const source = _.get(selectedChat, "stateVariables.source", "webchat");
      const text = _.get(resendingInteraction, "data.content[0].text", "");
      const message = {
        id: resendingInteraction?.RowKey,
        page_id: PartitionKey,
        user_id,
        isUser: false,
        isResolved: false,
        source,
        text,
      };
      resendingInteraction.status = MessageStatus.SENDING;
      const updatedLivechat = Object.assign({}, selectedChat, { interactions });
      commit("UPDATE_QUEUE_CHAT", { updatedLivechat });

      try {
        if (!isConnectionAvailable(state, navigator.onLine)) {
          throw Error("Socket is not connected!");
        }

        return await graph.mutate({
          mutation: gql`
            mutation ($newMessage: JSON) {
              livechatAPI {
                isAdded: reply(message: $newMessage)
              }
            }
          `,
          variables: {
            newMessage: message,
          },
        });
      } catch (error) {
        const sentryMessage = `Sending message error : ${JSON.stringify(
          error
        )}, message: ${JSON.stringify(message)}`;
        Sentry.captureException(new Error(sentryMessage));

        handleMessageSendingError(state, resendingInteraction.RowKey, updatedLivechat, commit);
        throw error;
      }
    }
  },

  GET_ALL_FIELD_DATA: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            livechatAPI {
              fetchAllFieldData
            }
          }
        `,
      })
      .then((result) => {
        const fieldData = _.get(result, "data.livechatAPI.fetchAllFieldData", {});
        commit("UPDATE_ALL_FIELD_DATA", fieldData);
      })
      .catch((error) => {
        throw error;
      });
  },

  SUBMIT_FORM_DATA: ({ state }, { chat, visitorProfileForm }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON, $visitorProfileForm: JSON) {
            livechatAPI {
              formDataSubmitted: submitFormData(chat: $chat, formData: $visitorProfileForm)
            }
          }
        `,
        variables: {
          chat,
          visitorProfileForm,
        },
      })
      .catch((error) => {
        const sentryMessage = `submit form data error: ${error}, chat: ${chat}, form: ${visitorProfileForm}`;
        Sentry.captureException(sentryMessage);
        throw error;
      });
  },
  UPDATE_LIVECHAT_STATUS: ({ state }, { chat, status }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation ($chat: JSON, $status: String) {
            livechatAPI {
              updateSessionStatus(chat: $chat, status: $status)
            }
          }
        `,
        variables: {
          chat,
          status,
        },
      })
      .catch((error) => {
        const sentryMessage = `submit form data error: ${error}, chat: ${chat}, status: ${status}`;
        Sentry.captureException(sentryMessage);
        throw error;
      });
  },

  UPDATE_USER_DETAILS: ({ state, commit }, { chat, userDetails }) => {
    const { user_id: userId, PartitionKey: partitionKey, RowKey } = chat;
    return graph
      .mutate({
        mutation: gql`
          mutation ($userId: ID!, $partitionKey: String!, $userDetails: JSON!) {
            livechatAPI {
              updateUserDetails(
                userId: $userId
                partitionKey: $partitionKey
                userDetails: $userDetails
              )
            }
          }
        `,
        variables: {
          userId,
          partitionKey,
          userDetails,
        },
      })
      .then((isSuccessful) => {
        const queueChatsToUpdate = _.pickBy(
          state.queueChatObject,
          (liveChatSessionDetails, rowKey) =>
            liveChatSessionDetails.user_id === userId &&
            liveChatSessionDetails.PartitionKey === partitionKey
        );
        _.forEach(queueChatsToUpdate, (liveChatSessionDetails, rowKey) => {
          const clonedChat = _.cloneDeep(liveChatSessionDetails);
          clonedChat.userDetails = userDetails;
          commit("UPDATE_QUEUE_CHAT", { updatedLivechat: clonedChat });
        });

        const resolvedChatsToUpdate = _.filter(
          state.resolvedChatsArray,
          (chatObj) => chatObj.user_id === userId && chatObj.PartitionKey === partitionKey
        );

        _.forEach(resolvedChatsToUpdate, (chat) => {
          const clonedChat = _.cloneDeep(chat);
          clonedChat.userDetails = userDetails;
          commit("UPDATE_RESOLVED_CHAT", { updatedLivechat: clonedChat });
        });

        const abandonedChatsToUpdate = _.filter(
          state.abandonedChatsArray,
          (chatObj) => chatObj.user_id === userId && chatObj.PartitionKey === partitionKey
        );

        _.forEach(abandonedChatsToUpdate, (chat) => {
          const clonedChat = _.cloneDeep(chat);
          clonedChat.userDetails = userDetails;
          commit("UPDATE_ABANDONED_CHAT", { updatedLivechat: clonedChat });
        });

        const monitorChatsToUpdate = _.filter(
          state.monitorChatsArray,
          (chatObj) => chatObj.user_id === userId && chatObj.PartitionKey === partitionKey
        );

        _.forEach(monitorChatsToUpdate, (chat) => {
          const clonedChat = _.cloneDeep(chat);
          clonedChat.userDetails = userDetails;
          commit("UPDATE_MONITOR_CHAT", { updatedLivechat: clonedChat });
        });
        return isSuccessful;
      });
  },
  TOGGLE_COBROWSE: ({ state, commit }, { chat, status }) => {
    const payload = {
      sessionPartitionKey: _.get(chat, "PartitionKey", ""),
      userId: chat.user_id,
      status,
    };
    return graph.mutate({
      mutation: gql`
        mutation ($userId: String, $sessionPartitionKey: String, $status: Boolean) {
          livechatAPI {
            toggleCobrowse(
              userId: $userId
              sessionPartitionKey: $sessionPartitionKey
              status: $status
            )
          }
        }
      `,
      variables: payload,
      fetchPolicy: "no-cache",
    });
  },
  FETCH_FILE_PURGE_STATUS: ({ state, commit }) => {
    return graph.query({
      query: gql`
        query {
          livechatAPI {
            getUpdatedNotifyFilePurge
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
  },
  CHECK_INTERACTION_IN_LIVECHATSESSION: ({ getters }, { RowKey }) => {
    const selectedChat = getters.selectedChat;
    const payload = {
      livechatsessionRowKey: selectedChat.RowKey,
      livechatsessionPartitionKey: selectedChat.PartitionKey,
      interactionRowKey: RowKey,
    };
    return rest("post", "check_interaction_in_livechatsession", payload);
  },
};

export default actions;
