<template>
  <!-- <el-button @click="download" size="mini" type="primary">Download</el-button> -->
  <el-dropdown>
    <el-button type="primary" plain size="mini" :loading="isBusy">
      Import / Export <i class="el-icon-arrow-down el-icon--right"></i>
      <input
        id="entities-uploader"
        ref="entities-uploader"
        type="file"
        style="display: none"
        accept="application/json, .csv"
        @change="onFileUpload"
      />
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="() => downloadCSV()">
        Download All Entities (csv)
      </el-dropdown-item>
      <el-dropdown-item v-if="$store.state.showAdvanced" @click.native="() => downloadJSON()">
        Download All Entities (json)
      </el-dropdown-item>
      <el-dropdown-item @click.native="() => downloadCSV(true)">
        Download Sample (csv)
      </el-dropdown-item>
      <el-dropdown-item v-if="$store.state.showAdvanced" @click.native="() => downloadJSON(true)">
        Download Sample (json)
      </el-dropdown-item>
      <!-- <el-dropdown-item @click.native="onUpload">Upload Entities (csv)</el-dropdown-item> -->
      <el-dropdown-item @click.native="onUpload">Upload Entities</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
const SUPPORTED_UPLOAD_TYPES = ["text/csv", "application/json"];
const SAMPLE_JSON = [
  {
    name: "type function",
    enabled: false,
    priority: 0,
    type: "function",
    replaceText: false,
    description: "A sample function type entity",
  },
  {
    name: "medication_type",
    enabled: false,
    priority: 0,
    type: "list",
    replaceText: true,
    list: {
      list1: ["A list1 item"],
      list2: ["A list2 item", "Another list2 item"],
    },
  },
];
import { gql } from "@apollo/client/core";
import XLSX from "xlsx";

export default {
  data() {
    return {
      isBusy: false,
    };
  },
  props: ["entities"],
  methods: {
    async saveBot(newBot) {
      try {
        this.isBusy = true;
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($bot: JSON!) {
              setBot(bot: $bot)
            }
          `,
          variables: {
            bot: newBot,
          },
        });
        await this.$store.dispatch("FETCH_BOT");
        this.$notify.success({
          title: "Success",
          position: "bottom-right",
          message: `Entities uploaded.`,
        });
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Failed to import entities.`,
        });
      } finally {
        this.isBusy = false;
      }
    },
    onFileUpload($event) {
      try {
        const uploadedFile = _.first($event.target.files);
        if (!uploadedFile || !_.includes(SUPPORTED_UPLOAD_TYPES, uploadedFile.type))
          throw new Error("Invalid file uploaded");

        if (uploadedFile.type === "application/json") {
          this.extractJson(uploadedFile);
        } else {
          this.extractCSV(uploadedFile);
        }
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Failed to import entities.`,
        });
      } finally {
        this.$refs["entities-uploader"].value = null;
      }
    },
    onUpload() {
      this.$refs["entities-uploader"].click();
    },
    extractJson(uploadedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          // TODO: CSV or JSON file
          // if csv, ignore changes to the function and regex types
          const data = JSON.parse(e.target.result);
          const uploadedEntities = data.map((entity) => {
            entity.name = _.snakeCase(entity.name);
            entity.modified = true;
            return entity;
          });
          const newBot = {
            entity: _.keyBy([...this.entities, ...uploadedEntities], "name"),
          };
          this.saveBot(newBot);
        } catch (error) {
          console.log(error);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to import entities.`,
          });
        } finally {
          this.$refs["entities-uploader"].value = null;
        }
      };
      reader.readAsBinaryString(uploadedFile);
    },
    extractCSV(uploadedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const wb = XLSX.read(data, {
            type: "binary",
            raw: true,
          });
          const firstSheetName = wb.SheetNames[0];
          const firstSheet = wb.Sheets[firstSheetName];
          const uploadedData = XLSX.utils.sheet_to_json(firstSheet); // Only get the first sheet
          const uploadedEntities = _.chain(uploadedData)
            .map((item) => {
              const entity = {
                name: item.name,
                type: item.type,
                modified: true,
                enabled: item.enabled == "TRUE",
                priority: parseInt(item.priority || "0"),
                replaceText: item.replaceText == "TRUE",
                listName: _.snakeCase(item.list),
                listValues: _.chain(item)
                  .pickBy((attr, key) => key.startsWith("synonym_"))
                  .values()
                  .value(),
              };
              return entity;
            })
            .reduce((accu, curr) => {
              if (curr.type == "list") {
                const list = _.get(accu, `${curr.name}.list`, {}) || {};
                list[curr.listName] = [...(list[curr.listName] || []), ...(curr.listValues || [])];
                curr.list = list;
              }
              delete curr.listName;
              delete curr.listValues;
              accu[curr.name] = curr;
              return accu;
            }, {})
            .value();
          const newBot = {
            entity: { ..._.keyBy(this.entities, "name"), ...uploadedEntities },
          };
          this.saveBot(newBot);
        } catch (error) {
          console.log(error);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to import entities.`,
          });
        } finally {
          this.$refs["entities-uploader"].value = null;
        }
      };
      reader.readAsBinaryString(uploadedFile);
    },
    downloadJSON(isSample = false) {
      const data = isSample ? SAMPLE_JSON : this.entities;
      const blob = new Blob([JSON.stringify(data, null, 4)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const fileName = this.$exportFilename(`${isSample ? "sample" : "all"}_entities`, "json");
      link.download = fileName;
      link.click();
    },
    downloadCSV(isSample = false) {
      const data = isSample ? SAMPLE_JSON : this.entities;
      console.log(isSample, data);
      let toExport = [];
      toExport.push(["name", "type", "priority", "enabled", "replaceText", "list"]);

      const numberOfSynonyms = 15;

      data.forEach((row) => {
        if (!_.isEmpty(row.list)) {
          _.forEach(row.list, (listValues, listName) => {
            const rowInput = [row.name, row.type, row.priority, row.enabled, row.replaceText];

            // if (listValues.length > numberOfSynonyms) {
            //   numberOfSynonyms = listValues.length;
            // }
            console.log(listName, listValues);
            rowInput.push(listName, ...listValues);
            toExport.push(rowInput);
          });
        } else {
          const rowInput = [row.name, row.type, row.priority, row.enabled, row.replaceText];
          toExport.push(rowInput);
        }
      });

      toExport[0].push(...Array(numberOfSynonyms).fill("synonym"));

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(toExport);
      XLSX.utils.book_append_sheet(wb, ws, "entities");

      const filename = this.$exportFilename(`${isSample ? "sample" : "all"}_entities`, "csv");
      XLSX.writeFile(wb, filename, {});
    },
  },
};
</script>
