import { render, staticRenderFns } from "./CreateAutomation.vue?vue&type=template&id=0bbbb5f8&"
import script from "./CreateAutomation.vue?vue&type=script&lang=js&"
export * from "./CreateAutomation.vue?vue&type=script&lang=js&"
import style0 from "./CreateAutomation.vue?vue&type=style&index=0&id=0bbbb5f8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports