// @ts-nocheck
import { gql } from "@apollo/client/core";
import { TrendingIntentType } from "@/helperMethods/trendingTopics/type";
export const mixin = {
  methods: {
    async getExamplesById(id: number): Promise<TrendingIntentType> {
      const {
        data: { data },
      } = await this.$apollo.query({
        query: gql`
          query ($id: Int) {
            data: getExamplesByTrendingId(id: $id)
          }
        `,
        variables: {
          id,
        },
      });
      return data;
    },
    async reCallApi(): Promise<void> {
      const filterDate = this.$store.state.filterDate;
      if (filterDate && filterDate.every((date) => date)) {
        const [startDate, endDate] = filterDate;
        await Promise.all([
          this.$store.dispatch("TRENDING_TOPICS_FILTERD_BY_RANGE", { startDate, endDate }),
          this.$store.dispatch("TRENDING_TOPICS"),
        ]);
      } else {
        await Promise.all([
          this.$store.dispatch("TRENDING_TOPICS_FIRST_TIME"),
          this.$store.dispatch("TRENDING_TOPICS"),
        ]);
      }
    },
  },
};
